import React from "react";
import { Typography } from "@mui/material";
import image from "../../img/PayWellLogoBlack.png";

import "./Footer.scss"; // Make sure to create a corresponding CSS file

const FooterLinkList = () => {
  return (
    <>
      <div className="footer__seperator"></div>
      <footer className="footer">
        <div className="footer-logo">
          {/* <img src="path_to_your_logo.png" alt="Logo" /> */}
          {/* Desktop PayWell Logo */}
          <a href="/">
            <Typography
              style={{ color: "#494949" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: "flex" }}
            >
              <img src={image} alt="PayWell Logo" style={{ height: "1em" }} />
            </Typography>
          </a>
          <a href="/">
            {/* Mobile PayWell Logo */}
            <Typography
              style={{ color: "#494949" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              {/* <img src={image} alt="PayWell Logo" style={{ height: "1em" }} /> */}
            </Typography>
          </a>
        </div>
        <div className="footer-links">
          <a className="footer__link" href="/">
            About Us
          </a>
          <a className="footer__link" href="/legal/privacy-policy">
            Privacy Policy
          </a>
          <a className="footer__link" href="/legal/terms-conditions">
           Terms & Conditions
          </a>
          <a className="footer__link" href="/contact">
            Contact Us
          </a>
        </div>
      </footer>
    </>
  );
};

export default FooterLinkList;
