import React, { useState, useEffect } from "react";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext.js";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BasicDateRangePicker from "../DateRangePicker";
import ScrapeStatus from "../ScrapeStatus/ScrapeStatus";
import Dropzone from "../Dropzone/Dropzone";
import { Button, Paper, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ClubReadyAccountSelectorSkeleton from "../ClubReadyAccountSelector/ClubReadyAccountSelectorSkeleton";

import { useClubReady } from "../../contexts/ClubReadyContext";
import ClubReadyAccountSelector from "../ClubReadyAccountSelector/ClubReadyAccountSelector";
import { findStudiosWithoutAccount } from "../../contexts/StudioScrapeStatus/utility-functions.js";
import Alert from "@mui/material/Alert";
import TransitionAlert from "../Alert/TransitionAlert";
import { useSettingsContext } from "../../contexts/SettingsContext.js";
import { formatDate } from "../../utility-functions/utility-functions.js";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import { useAuth } from "../../contexts/AuthContext.js";
import "./tabs.scss";
import DynamicTable from "../Table/DynamicTable.js";
import NoDataComponent from "../NoDataComponent/NoDataComponent.js";

const initialTableData = {
  rows: [
    { id: 1, payPeriod: "2024-01-01 - 2024-01-15", status: "Processed", timeAgo: "2 days ago", action: "View Details" },
    { id: 2, payPeriod: "2024-01-16 - 2024-01-31", status: "Pending", timeAgo: "5 days ago", action: "View Details" }
  ]
};



function containsNull(arr) {
  let containsNull = false;
  arr = arr.filter(
    (e) => e !== null || e !== undefined || e.length !== 0 || e !== ""
  );
  arr.forEach((e) => {
    if (e === null || e === undefined || e.length == 0 || e == "") {
      containsNull = true;
    }
  });
  return containsNull;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={`tab-panel tab-panel--${index} clubready-reports__tab-panel`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// For Accessibility
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function convertSessionPathToSelectedDates(dateRange) {
  // Extract the date part from the input string
  const datePart = dateRange.split('/')[1];

  // Split the date part into start and end dates
  const dates = datePart.split('_');

  // Convert each date from "mm-dd-yyyy" to "mm/dd/yyyy"
  const formattedDates = dates.slice(0, 2).map(date => {
    // Replace dashes with slashes
    return date.replace(/-/g, '/');
  });

  return formattedDates;
}

export default function DropzoneScrapeTabs(props) {
  const { getUID, getFirebaseJwtToken } = useAuth();

  const { reportCompilerState, reportCompilerDispatch } = useSettingsContext();
  const {
    currentlySelectedStudios,
    selectedDates,
    grabReportsButtonDisabled,
    scrapeErrors,
    dispatchScrapeErrors,
    setGrabReportsButtonDisabled,
    tableData,
    dispatchTableData,
    useLatestRunData,
    setNewPayroll,
    setExistingPayroll,
    getPayrollHistory
  } = useStudioScrapeStatus();

  const [sessionPath, setSessionPath] = useState("");


  const { scrapeVisible, scrapeAccounts } = useClubReady();
  const [grabReportsButtonLoading, setGrabReportsButtonLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);



  const [pageLoading, setPageLoading] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue == 1) {
      getPayrollHistory();
    }
    setValue(newValue);
  };

  const createS3Session = async (bucketName, folderPath) => {

    try {
      const token = await getFirebaseJwtToken();

      const response = await API.post(
        "paywellAPIResource",
        "/createS3BucketFolder",
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: {
            path: folderPath,
            bucketName,
          },
          queryStringParameters: {
            path: folderPath,
            bucketName,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error creating folder:", error);
      return false;
    }
  };


  const handleCreateNewPayroll = async () => {

    setGrabReportsButtonLoading(true);
    const uid = getUID();
    const newFolderDate = new Date().getTime();
    const payPeriod = `${formatDate(new Date(selectedDates[0]))}_${formatDate(
      new Date(selectedDates[1])
    )}`;
    const folderPath = `${uid}/${payPeriod}_${newFolderDate}/`;

    let res = await createS3Session("paywell-user-runs", folderPath); // make new session for re-runs?
    if (res) {
      // CREATE A FOLDER INSIDE THE NEW SESSION WITH THE NAME "settings_id_1"
      let settingsId = getUrlVariableValue("settingsId");
      if (settingsId) {
        let settingIdFolderPath = folderPath + `settings_id_${settingsId}/`;
        let settingsIdFolderRes = await createS3Session("paywell-user-runs", settingIdFolderPath); // make new session for re-runs?
      }
    }

    reportCompilerDispatch({ type: "SET_SELECTED_DATES", selectedDates });
    setNewPayroll(true);
    setGrabReportsButtonLoading(false);
    setGrabReportsButtonDisabled(true);
  };

  const handleInitExistingPayroll = async (sessionPath) => {


    setSessionPath(sessionPath);

    setExistingPayroll(true);





    await useLatestRunData(getUID(), sessionPath);



    setExistingPayroll(false);
   

  };
  const handleUseExistingPayroll = async (uid, sessionPath) => {



    await useLatestRunData(uid, sessionPath);



    setExistingPayroll(false);



    // await useLatestRunData(uid, sessionPath);

  };



  function checkAllStudiosHaveAccounts() {
    if (scrapeAccounts.logins?.length > 0) {
      let studiosWithoutAccount = findStudiosWithoutAccount(
        currentlySelectedStudios,
        scrapeAccounts.logins
      );
      if (studiosWithoutAccount.length > 0) {
        let error = (
          <Alert className="clubready-scrape__error" severity="error">
            Missing ClubReady account for the following studios:
            <span style={{ fontWeight: "bold" }}>
              {studiosWithoutAccount.join(", ")}
            </span>
          </Alert>
        );
        let error2 = (
          <TransitionAlert className="clubready-scrape__error" severity="error">
            Missing ClubReady account for the following studios:
            <span style={{ fontWeight: "bold" }}>
              {studiosWithoutAccount.join(", ")}
            </span>
          </TransitionAlert>
        );
        dispatchScrapeErrors({
          type: "SET_ALL_ERRORS",
          errors: [error2],
        });
      } else {
        dispatchScrapeErrors({
          type: "SET_ALL_ERRORS",
          errors: [],
        });
      }
    } else {
      dispatchScrapeErrors({
        type: "SET_ALL_ERRORS",
        errors: [],
      });
    }
  }

  useEffect(() => {

    checkAllStudiosHaveAccounts();
  }, [scrapeAccounts]);




  return (
    <>

      <Box className={`tabs-container clubready-reports`}>
        <Tabs
          value={value}
          className="tabs  clubready-reports__tabs"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Create Payroll" {...a11yProps(0)} />
          <Tab id="payroll-history-tab" label="Payroll History" {...a11yProps(1)} />
          <Tab label="Upload Payroll" {...a11yProps(2)} />
        </Tabs>

        {scrapeAccounts.logins.some(login => login.validated === "pending") || scrapeAccounts.status == "loading" || pageLoading ? (
          <>
            <TabPanel className="clubready-scrape" value={value} index={0}>
              <Box component={Paper} className="clubready-scrape__form clubready-scrape__form--loading">
                <div className="clubready-scrape__account-selector">
                  <ClubReadyAccountSelectorSkeleton />
                </div>

                <div className="clubready-scrape__date-picker-container">
                  <div className="clubready-scrape__date-picker-label"></div>
                  <div className="clubready-scrape__date-picker">
                    <BasicDateRangePicker
                      disabled={true}
                      marginTop={".3em"}
                      marginBotttom={".3em"}
                    ></BasicDateRangePicker>
                  </div>
                </div>

                <Button
                  className="clubready-scrape__button"
                  disabled={true}
                  id="grabReportsButton"
                  variant="contained"
                  onClick={handleCreateNewPayroll}
                >
                  Create New Payroll
                </Button>
              </Box>

            </TabPanel>
            <TabPanel value={value} index={1} className="dynamic-table-container">
              <DynamicTable rows={tableData.rows} loading={tableData.loading} handleInitExistingPayroll={handleInitExistingPayroll} />
            </TabPanel>
            <TabPanel value={value} index={2} sx={{ display: "flex" }}>
              {currentlySelectedStudios.map((studio, i) => {
                return <Dropzone key={i} studio={studio} studioIndex={i} />;
              })}
            </TabPanel>
          </>
        ) : scrapeAccounts.status == "empty" ? (
          <div className="clubready-scrape__account-selector">
            <ClubReadyAccountSelector></ClubReadyAccountSelector>
          </div>
        ) : (
          <>
            <TabPanel className="clubready-scrape " value={value} index={0}>
              <div className="clubready-scrape__errors-container">
                {scrapeErrors}
              </div>

              <Box component={Paper} className="clubready-scrape__form ">
                <div className="clubready-scrape__account-selector">
                  <ClubReadyAccountSelector></ClubReadyAccountSelector>
                </div>

                <div className="clubready-scrape__date-picker-container">
                  <div className="clubready-scrape__date-picker-label">
                    {/* <InputLabel>Select Pay Period for Reports</InputLabel> */}
                  </div>
                  <div className="clubready-scrape__date-picker">
                    <BasicDateRangePicker
                      disabled={!scrapeVisible}
                      marginTop={".3em"}
                      marginBotttom={".3em"}
                    ></BasicDateRangePicker>
                  </div>
                </div>
                <LoadingButton
                  loading={grabReportsButtonLoading}
                  className="clubready-scrape__button"
                  disabled={
                    !grabReportsButtonLoading && (
                      grabReportsButtonDisabled ||
                      reportCompilerState.scrapeInfo.isScrapeActive)
                  }
                  id="grabReportsButton"
                  variant="contained"
                  onClick={handleCreateNewPayroll}
                >
                  Create New Payroll
                </LoadingButton>

              </Box>

              <div className="clubready-scrape__status">
                <ScrapeStatus />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="dynamic-table-container">
              <DynamicTable rows={tableData.rows} loading={tableData.loading} dispatch={dispatchTableData} handleInitExistingPayroll={handleInitExistingPayroll} />
            </TabPanel>
            <TabPanel value={value} index={2} sx={{ display: "flex" }}>
              {currentlySelectedStudios.map((studio, i) => {
                return <Dropzone key={i} studio={studio} studioIndex={i} />;
              })}
            </TabPanel>
          </>
        )}
      </Box>
    </>
  );
}
