import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";

export default function AttributeListBox(props) {
  const [currentlySelected, setvalue] = useState(props.default);
  let readOnly = props.readOnly === undefined ? false : props.readOnly;
  let placeholder =
    props.placeholder === undefined ? "" : props.placeholder;
  let limitTags = props.limitTags === undefined ? 4 : props.limitTags;
  let grouping = props.grouping === undefined ? null: props.grouping;
  let disabled = props.disabled === undefined ? false : props.disabled;
  let noOptions = props.noOptions === undefined ? "No Options" : props.noOptions;
  let options = props.options === undefined || props.options === null || Object.keys(props.options).length === 0 ? [] : props.options;
  let clearOnBlur = props.clearOnBlur === undefined ? true : props.clearOnBlur;

  const handleChange = (event, value) => {
    setvalue(value);

    if (props.onChange) {
      props.onChange(value, props.id);
    }
    if (props.setIsStudioSelected) {
      if (currentlySelected.length > 0) {
        props.setIsStudioSelected(true);
      } else {
        props.setIsStudioSelected(false);
      }
    }
  };

  return (
    <Autocomplete
      noOptionsText={noOptions}
      autoSelect={props.autoSelect}
      disabled={disabled}
      sx={{ width: props.size }}
      className={props.className}
      disableClearable
      limitTags={limitTags}
      readOnly={readOnly}
      autoComplete
      multiple
      id={props.id}
      options={options.map((option) => option)}
      defaultValue={currentlySelected}
      onChange={handleChange}
      freeSolo={props.freeSolo}
      groupBy={grouping}
      clearOnBlur={clearOnBlur}
      //getOptionLabel={removeSubstrings}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              event.stopPropagation();
            } else if (event.key === "Enter" && !props.freeSolo) {
              event.stopPropagation();
            }
          }}
        />
      )}
    />
  );
}

export function SingleAttributeListBox(props) {
  const [currentlySelected, setvalue] = useState(props.default);
  let readOnly = props.readOnly === undefined ? false : props.readOnly;
  let placeholder =
    props.placeholder === undefined ? "" : props.placeholder;

  const handleChange = (event, value) => {
    setvalue(value);

    if (props.onChange) {
      props.onChange(value, props.id);
    }
    if (props.setIsStudioSelected) {
      if (currentlySelected.length > 0) {
        props.setIsStudioSelected(true);
      } else {
        props.setIsStudioSelected(false);
      }
    }
  };

  return (
    <Autocomplete
      autoSelect
      className={props.className}
      disableClearable
      readOnly={readOnly}
      autoComplete
      multiple={false}
      id={props.id}
      options={props.options.map((option) => option)}
      defaultValue={currentlySelected}
      onChange={handleChange}
      freeSolo={props.freeSolo}
      style = {{width: 200}}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          size="large"
          className={`${props.className}-text-field`}
          label={props.label}
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              event.stopPropagation();
            } else if (event.key === "Enter" && !props.freeSolo) {
              event.stopPropagation();
            }
          }}
        />
      )}
    />
  );
}

export function SingleValueInput(props) {
  const [currentlySelected, setvalue] = useState(props.default);

  const handleChange = (event, value) => {
    setvalue(value);

    if (props.onChange) {
      props.onChange(value, props.id);
    }
    if (props.setIsStudioSelected) {
      if (currentlySelected.length > 0) {
        props.setIsStudioSelected(true);
      } else {
        props.setIsStudioSelected(false);
      }
    }
  };

  return (
    <TextField
      className={props.className}
      id={props.id}
      select
      label="Select"
      value={currentlySelected}
      // onChange={handleChange}
      helperText="Please select an option"
      variant="outlined"
      freeSolo
    >
          {props.options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
    // <Autocomplete
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       variant="standard"
    //       size="small"
    //       className={`${props.className}-text-field`}
    //       label={props.label}
    //       placeholder={placeholder}
    //       InputProps={{
    //         endAdornment: (
    //           <InputAdornment position="end">
    //             <SearchRoundedIcon style={{ opacity: 0.4 }} />
    //           </InputAdornment>
    //         ),
    //       }}
    //       onKeyDown={(event) => {
    //         if (event.key === "Backspace") {
    //           event.stopPropagation();
    //         } else if (event.key === "Enter" && !props.freeSolo) {
    //           event.stopPropagation();
    //         }
    //       }}
    //     />
  //     )}
  //   />
  //   // </Stack>
  );
}
