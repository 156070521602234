import React, { useState, useContext, useRef } from "react";
import { Typography } from "@mui/material";
import { Box, Divider } from "@mui/material";
//import { attendeeStatuses } from "../CommonVariables";
import FullClassWithNonAttendees from "./FullClassWithNonAttendees";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { RevertList } from "../../Components/RevertList";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function AdvancedClassSettings() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const { getUID } = useAuth();

  const [toggle, setToggle] = useState(
    settings[getUrlVariableValue("settingsId")].classSettings.general.fullClassSettings
      .valid
  );

  const handleUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].classSettings.general,
      [id],
      value
    );
    setChanges(newRev);

    // setStudioSettings({
    //   ...studioSettings,
    //   classSettings: {
    //     ...studioSettings.classSettings,
    //     general: { ...studioSettings.classSettings.general, [id]: value },
    //   },
    // });
    dispatch({
      type: "UPDATE_GENERAL_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  const handleToggleChange = (value, id) => {
    setToggle(value);

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "fullClassSettings-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.general.fullClassSettings
        .valid,
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  return (
    <Box>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>
          A {settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} is considered RESCHEDULED and {settings[getUrlVariableValue("settingsId")].generalSettings.specialistsName} are not paid if ALL
          {settings[getUrlVariableValue("settingsId")].generalSettings.clientsName} have statuses of
        </Typography>
        <Typography> </Typography>
        {/* <MultipleSelectCheckmarks></MultipleSelectCheckmarks> */}
        <MultipleSelectCheckmarks
          tag="Status"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings.general
              .rescheduledStatuses
          }
          onChange={handleUpdate}
          id="rescheduledStatuses"
          multiple
          size={400}
          //options={attendeeStatuses}
          options={settings[getUrlVariableValue("settingsId")].generalSettings.attendeeStatuses}
          label="Statuses"
          name="Statuses"
        ></MultipleSelectCheckmarks>
        {/* <Typography>
          the class is considered RESCHEDULED and the instructor should not be
          paid
        </Typography> */}
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>
          If a {settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} would have been full with No-Shows and Late Cancels, it's
          paid out as if it were full:
        </Typography>
        <BooleanToggleElement
          onChange={handleToggleChange}
          id="valid"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings.general
              .fullClassSettings.valid
          }
        ></BooleanToggleElement>
      </Box>

      <FullClassWithNonAttendees
        toggle={toggle}
        id="fullClassSettings"
      ></FullClassWithNonAttendees>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>{settings[getUrlVariableValue("settingsId")].generalSettings.clientsName} types paid out differently</Typography>
        <ReplacementList id="attendeePayment"></ReplacementList>
      </Box>
    </Box>
  );
}

function ReplacementList(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const { getUID } = useAuth();

  const handleMappingChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "attendee-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.general.attendeePayment,
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        { id: id, attendee: "", payment: "", isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "attendee" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add {settings[getUrlVariableValue("settingsId")].generalSettings.clientName} Pay
        </Button>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };
  const [rows, setRows] = React.useState(
    settings[getUrlVariableValue("settingsId")].classSettings.general.attendeePayment
  );
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    let newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
    handleMappingChange(newRows, "attendeePayment");
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    handleMappingChange(rows, "attendeePayment");
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    let newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    setRows(newRows);

    handleMappingChange(newRows, "attendeePayment");

    return updatedRow;
  };

  const columns = [
    {
      field: "attendee",
      headerName: "Attendee Identifier",
      width: 320,
      editable: true,
    },
    {
      field: "payment",
      headerName: "Payment",
      width: 320,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridPro
        id="attendeePayment"
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
