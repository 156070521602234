import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";

export function readSlingFile(json, studiosInformation) {
  const timeArray = [];
  let staffInFile = [];

  let nameI = 0;
  let actualI = 0;
  let locationI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isSlingHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("EMPLOYEE")) {
          nameI = u;
        } else if (json[i][u].includes("ACTUAL")) {
          actualI = u;
        } else if (json[i][u].includes("LOCATION")) {
          locationI = u;
        }
      }
      correctFileType = true;
    } else if (isSlingDataRow(json[i])) {
      let hours = json[i][actualI];
      if (isNaN(parseFloat(hours))) {
        hours = 0;
      }
      let hourRate = findHourlyPayRate(
        json[i][nameI],
        "All",
        studiosInformation
      );

      if (json[i][actualI].length > 0) {
        let location = json[i][locationI];
        if (
          location.length === 0 ||
          location === "" ||
          location === null ||
          location === undefined
        ) {
          location = "All";
        }

        if (hours !== 0) {
          const newStaff = {
            name: json[i][nameI],
            type: "time",
            location: location,
          };
          
          let isDuplicate = staffInFile.some(staff => 
            staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
          );
          
          if (!isDuplicate) {
            staffInFile.push(newStaff);
          }

          // staffInFile.push({
          //   name: json[i][nameI],
          //   type: "time",
          //   location: location,
          // });
        }

        let timeEvent = createSlingTimeEvent(
          json[i][nameI],
          location,
          hours,
          hourRate,
          "Regular"
        );
        timeArray.push(timeEvent);
      }
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isSlingHeaderRow(row) {
  return (
    row[0].includes("EMPLOYEE") ||
    row[1].includes("DATE") ||
    row[2].includes("EMPL. ID")
  );
}

function isSlingDataRow(row) {
  return row[0] !== null && row[0] !== "" && row[0] !== "TOTAL";
}

function createSlingTimeEvent(name, location, hoursStr, hourRate, type) {
  let hours = parseFloat(hoursStr);
  return new TimeEvent(name, location, hours, hourRate, type, hours * hourRate);
}

// remote all reportCompilerState from being used within this functon
export function slingBasicFileConverter(json, reportCompilerState) {
  let staffInFile = [];

  const timeArray = [];
  let nameI = 0;
  let hoursI = 0;
  let locationI = 0;
  let positionI = 0;
  let clockIn = 0;
  let clockOut = 0;
  let dateI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isSlingHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("EMPLOYEE") && !json[i][u].includes("ID")) {
          nameI = u;
        } else if (
          json[i][u].includes("SHIFT") &&
          !json[i][u].includes("SCH.")
        ) {
          hoursI = u;
        } else if (json[i][u].includes("LOCATION")) {
          locationI = u;
        } else if (json[i][u].includes("POSITIONS")) {
          positionI = u;
        } else if (json[i][u].includes("CLOCK IN") && !json[i][u].includes("DIST.")) {
          clockIn = u;
        } else if (json[i][u].includes("CLOCK OUT") && !json[i][u].includes("DIST.")) {
          clockOut = u;
        } else if (json[i][u].includes("DATE")) {
          dateI = u;
        }
      }
      correctFileType = true;
    } else if (isSlingBasicDataRow(json[i])) {
      let hours = json[i][hoursI];
      if (isNaN(parseFloat(hours))) {
        hours = 0;
      }
      let hourRate = findHourlyPayRate(
        json[i][nameI],
        "All",
        reportCompilerState.studiosInformation
      );

      if (json[i][hoursI].length > 0) {
        let location = findSlingLocation(
          json[i][locationI],
          reportCompilerState.payrollInformation.studiosInInput
        );
        if (
          location.length === 0 ||
          location === "" ||
          location === null ||
          location === undefined
        ) {
          location = "All";
        }

        if (hours !== 0) {
          const newStaff = {
            name: json[i][nameI],
            type: "time",
            location: location,
          };
          
          let isDuplicate = staffInFile.some(staff => 
            staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
          );
          
          if (!isDuplicate) {
            staffInFile.push(newStaff);
          }

          //staffInFile.push({ name: json[i][nameI], type: "time", "location": location }); // bad
        }

        let timeEvent = createSlingTimeEvent(
          json[i][nameI],
          location,
          hours,
          hourRate,
          json[i][dateI] +
            " (" +
            json[i][clockIn] +
            " - " +
            json[i][clockOut] +
            ") Regular"
        );
        if (
          !reportCompilerState.inputFilesArrays.questions.punchDetailInStaffTabs
        ) {
          timeEvent.detail = true;
        }
        timeArray.push(timeEvent);
      }
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isSlingBasicDataRow(row) {
  return (
    row[0] !== null &&
    row[0] !== "" &&
    row[0] !== "TOTAL" &&
    row[1] &&
    row[1].length > 2
  );
}

function findSlingLocation(locationStr, settingsPresetStudios) {
  // let studios = reportCompilerState.payrollInformation.studiosInInput;
  let studios = settingsPresetStudios;
  let location = closestMatch(locationStr, studios);

  return location;
}

function closestMatch(str, arr) {
  let minDistance = Infinity;
  let closestStr = "";

  for (let i = 0; i < arr.length; i++) {
    const distance = levenshteinDistance(str, arr[i]);

    if (distance < minDistance) {
      minDistance = distance;
      closestStr = arr[i];
    }
  }

  return closestStr;
}

function levenshteinDistance(s, t) {
  const m = s.length;
  const n = t.length;
  const d = [];

  for (let i = 0; i <= m; i++) {
    d[i] = [i];
  }

  for (let j = 0; j <= n; j++) {
    d[0][j] = j;
  }

  for (let j = 1; j <= n; j++) {
    for (let i = 1; i <= m; i++) {
      if (s[i - 1] === t[j - 1]) {
        d[i][j] = d[i - 1][j - 1];
      } else {
        d[i][j] = Math.min(
          d[i - 1][j] + 1, // deletion
          d[i][j - 1] + 1, // insertion
          d[i - 1][j - 1] + 1 // substitution
        );
      }
    }
  }

  return d[m][n];
}
