import React, {
  useContext,
  useState,
  useReducer,
  useEffect,
  useRef,
} from "react";
import { API } from "aws-amplify";
import Bottleneck from "bottleneck";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { useClubReady } from "../ClubReadyContext";
import { useStepperContext } from "../../components/LinearStepper/stepperContext";
import { useSettingsContext } from "../SettingsContext";
import { getStudioAndReportTypeFromJSON } from "../ReportCompiler/utility-functions.js";
import { selectedDatesValid } from "./utility-functions.js";
import {
  getStudioAndReportTypeForWorkbook,
  minimumFilesUploaded,
} from "../ReportCompiler/utility-functions.js";
import { useSnackBar } from "../SnackBarContext/SnackBarContext.js";

import {
  getFileNameFromSignedURL,
  getPrevDay,
  findStudiosWithoutAccount,
  getAccountFromStudioName,
  missingTimeDetailStaff,
  updateStudioStatuses,
} from "./utility-functions.js";

// import { read, utils } from "xlsx";
import { read } from  "@sheet/core";

import {
  getUrlVariableValue,
  updateObject,
  formatDate,
} from "../../utility-functions/utility-functions.js";

import { getAllUserPayrollRows, getLatestRunData } from "../../utility-functions/AWS/S3-Helper-Functions.js";
import { getReportCompilerStateFromLatestSessionSnapshot } from "../../utility-functions/AWS/S3-Helper-Functions.js";
export const TESTING_MODE = false;
const TEST_DATE = "7-24";
const PAY_PERIOD_START_DATE = "07/05/2023";
const PAY_PERIOD_END_DATE = "07/19/2023";










// const limiter = new Bottleneck({
//   reservoir: 5, // Maximum number of requests allowed in the given interval
//   reservoirRefreshAmount: 5, // Number of requests to add to the reservoir after the interval
//   reservoirRefreshInterval: 1000, // Interval duration in milliseconds
// });
const limiter = new Bottleneck({
  reservoir: 1, // Maximum number of requests allowed in the given interval
  reservoirRefreshAmount: 1, // Number of requests to add to the reservoir after the interval
  reservoirRefreshInterval: 2000, // Interval duration in milliseconds
});

const StudioScrapeStatusContext = React.createContext();

export function useStudioScrapeStatus() {
  return useContext(StudioScrapeStatusContext);
}

export const selectedStudiosReducer = (state, action) => {
  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    // currentlySelectedStudiosDispatch({
    //   type: "SET_SELECTED_STUDIOS"
    //   studios: [],
    // })
    case "SET_SELECTED_STUDIOS":
      return action.studios;
    // currentlySelectedStudiosDispatch({
    //   type: "ADD_STUDIO",
    //   studio: ,
    //
    // })
    case "ADD_STUDIO":
      newState.push(action.studio);
      return newState;
    // currentlySelectedStudiosDispatch({
    //   type: "REMOVE_LOGIN",
    //   email: accounts[0].email,
    //   password: accounts[0].password,
    //   tagName: accounts[0].tagName,
    //   uid: uid,
    //        }
    // })
    case "REMOVE_STUDIO":
      return newState.filter((studio) => studio !== action.studio);

    default:
      return state;
  }
};
export function StudioScrapeStatusProvider({ children }) {
  const showSnackBar = useSnackBar();
  const { getUID } = useAuth();
  const { scrapeAccounts, scrapeAccountsDispatch } = useClubReady();

  const [grabReportsButtonDisabled, setGrabReportsButtonDisabled] =
    useState(true);
  var [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);
  const { dispatchStepper, stepperState } = useStepperContext();
  const isAccountingReports = false;
  var retryScrapeCount = useRef(0);
  var retryScrapeMax = 1;
  var isScrapeLoading = true;
  const { reportCompilerState, reportCompilerDispatch } = useSettingsContext();
  const [newPayroll, setNewPayroll] = useState(false);
  const [existingPayroll, setExistingPayroll] = useState(false);
  const { currentUser, getFirebaseJwtToken } = useAuth();
  const [accountingStudios, setAccountingStudios] = useState([]);
  var [inputFileUploaded, setInputFileUploaded] = useState(false);
  console.log("studiosInInput: ",  reportCompilerState?.payrollInformation?.studiosInInput);
  const [currentlySelectedStudios, currentlySelectedStudiosDispatch] =
    useReducer(
      selectedStudiosReducer,
      reportCompilerState?.payrollInformation?.studiosInInput || []
    );

  const allBookingEventFilesUploaded = () => {
    var uploadCnt = 0;
    Object.entries(reportCompilerState.inputFilesArrays).forEach(
      ([key, value]) => {
        if (key.includes("bel")) {
          if (value.length === 0) {
            return false;
          } else {
            uploadCnt++;
          }
        }
      }
    );
    if (currentlySelectedStudios.length === uploadCnt) {
      return true;
    } else {
      return false;
    }
  };

  // Hooks
  const statusReducer = (state, action) => {
    let newState;
    switch (action.type) {
      //  dispatch({
      //   type: UPDATE_SCRAPE_STATUS_PROPERTY",
      //   path: `formatSettings.processorMapping.columns.name`,
      //   value: name,
      //   replace: true
      // });
      case "UPDATE_SCRAPE_STATUS_PROPERTY":
        newState = [...state];
        updateObject(
          newState,
          action.path,
          action.value,
          action.hasOwnProperty("replace") ? action.replace : true
        );

        return newState;
      case "INIT":

        if (!currentlySelectedStudios) {
          throw new Error("currentlySelectedStudios is undefined");
        }

        //       reportCompilerDispatch({
        //   type: "UPDATE_REPORT_COMPILER_SCRAPE_STATUS",
        //   value: true,
        // });


        return currentlySelectedStudios.map((studioName, studioIndex) => ({
          key: studioName,
          status: "loading",
          error: false,
          completedReportsCount: 0,
          reportsCount: reportCompilerState.scrapeInfo.reportsNeeded.length,
          studioIndex: studioIndex,
          reports: reportCompilerState.scrapeInfo.fullNameReportsNeeded.map(
            (report, reportIndex) => ({
              status: "loading",
              fileType:
                reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
              fileName: report,
              fileIndex: reportIndex,
              studioIndex: studioIndex,
              key: reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
              workbook: false,
            })
          ),
        }));


      case "SET_EXISTING_PAYROLL":


        if (!action.newStatus) {
          throw new Error("action.newStatus is undefined");
        }







        return action.newStatus;


      case "UPDATE_STUDIO_VALUE":
        newState = [...state];
        if (typeof action.studioIndex !== "number") {
          throw new TypeError("studioIndex is not a number");
        }
        if (!newState[action.studioIndex]) {
          throw new Error(
            `Studio with index ${action.studioIndex} does not exist in the state`
          );
        }
        newState[action.studioIndex][action.key] = action.value;
        return newState;

      case "UPDATE_REPORT_VALUE":
        newState = [...state];
        if (typeof action.studioIndex !== "number") {
          throw new TypeError("studioIndex is not a number");
        }
        if (!newState[action.studioIndex]) {
          throw new Error(
            `${currentlySelectedStudios[action.studioIndex]} with index ${action.studioIndex
            } does not exist in the state`
          );
        }
        if (!newState[action.studioIndex].reports[action.reportIndex]) {
          throw new Error(
            `Report with index ${action.reportIndex} does not exist in studio ${action.studioIndex}`
          );
        }
        if (
          !newState[action.studioIndex].reports[action.reportIndex][action.key]
        ) {
          throw new Error(
            `Key ${action.key} does not exist in the report object`
          );
        }

        newState[action.studioIndex].reports[action.reportIndex][action.key] =
          action.value;

        return newState;


      case "UPDATE_MULTIPLE_REPORTS":
        newState = [...state];

        action.reportsToUpdate.forEach(reportObj => {
          const {
            status,
            fileType,
            fileName,
            fileIndex,
            studioIndex,
            key,
            workbook
          } = reportObj;

          // You can add additional conditions specific to 'fileType' as needed
          if (typeof studioIndex !== 'number') {
            throw new TypeError("studioIndex must be a number");
          }
          if (!newState[studioIndex]) {
            throw new Error(`Studio with index ${studioIndex} does not exist in the state`);
          }
          if (!newState[studioIndex].reports[fileIndex]) {
            throw new Error(`Report ${fileType} with index ${fileIndex} does not exist in studio index ${studioIndex}`);
          }

          // Update the report with the new information from reportObj
          newState[studioIndex].reports[fileIndex] = { ...newState[studioIndex].reports[fileIndex], ...reportObj };
        });

        return newState;

      case "UPDATE_REPORT":
        newState = [...state];
        const {
          status,
          fileType,
          fileName,
          fileIndex,
          studioIndex,
          key,
          workbook,
        } = action.reportObj;
        if (fileType == "agree") {
        }

        if (typeof studioIndex !== "number") {
          throw new TypeError("studioIndex is not a number");
        }
        if (!newState[studioIndex]) {
          throw new Error(
            `Studio with index ${studioIndex} does not exist in the state`
          );
        }
        if (!newState[studioIndex].reports[fileIndex]) {
          throw new Error(
            `Report ${fileType} with index ${fileIndex} does not exist in ${currentlySelectedStudios[studioIndex]}  with index ${studioIndex}`
          );
        }
        newState[studioIndex].reports[fileIndex] = action.reportObj;

        return newState;

      case "DELETE_REPORT":
        if (
          !action.fileType ||
          typeof action.studioIndex !== "number" ||
          typeof action.reportIndex !== "number" ||
          !action.key ||
          !action.value
        ) {
          console.error("Invalid action: DELETE_REPORT");
          return state;
        }

        // removeReport(action.fileType, action.studioIndex + 1);
        let reportPropertyName = action.fileType + (action.studioIndex + 1);
        reportCompilerDispatch({
          type: "REMOVE_REPORT_COMPILER_REPORT",
          reportPropertyName,
        });
        newState = [...state];
        if (newState[action.studioIndex]?.reports?.[action.reportIndex]) {
          newState[action.studioIndex].reports[action.reportIndex][action.key] =
            action.value;
          newState[action.studioIndex].reports[action.reportIndex]["fileName"] =
            reportCompilerState.scrapeInfo.fullNameReportsNeeded[
            action.reportIndex
            ];
        } else {
          console.error("Invalid state: DELETE_REPORT");
        }

        return newState;

      case "UPDATE_STUDIO_PROGRESS":
        newState = [...state];

        if (!Array.isArray(newState)) {
          console.error("Invalid state: UPDATE_STUDIO_PROGRESS");
          return state;
        }

        newState.forEach((studio, studioIndex) => {
          if (!studio || !Array.isArray(studio.reports)) {
            console.error("Invalid state: UPDATE_STUDIO_PROGRESS");
            return;
          }

          // let studioReports = studio.reports.map((report) => report["status"]);
          let studioReports = studio.reports.map((report, i) => {
            return report["status"];
          });
          let completedReportsCount = studioReports.filter(
            (status) => status === "done"
          ).length;
          newState[studioIndex]["completedReportsCount"] =
            completedReportsCount;

          if (studioReports.includes("loading")) {
            newState[studioIndex]["status"] = "loading";
          } else if (studioReports.includes("error")) {
            newState[studioIndex]["status"] = "error";
          } else if (studioReports.includes("idle")) {
            newState[studioIndex]["status"] = "idle";
          } else {
            newState[studioIndex]["status"] = "done";
          }
        });

        if (!Array.isArray(newState)) {
          console.error("Invalid state: UPDATE_STUDIO_PROGRESS");
          return state;
        }

        let isScrapeDoneRunning = newState.every(
          (studio) => studio.status !== "loading"
        );

        if (isScrapeDoneRunning) {
          isScrapeLoading = false;

          // reportCompilerDispatch({
          //   type: "UPDATE_REPORT_COMPILER_SCRAPE_STATUS",
          //   value: false,
          // });
          if (minimumFilesUploaded(reportCompilerState)) {
            dispatchStepper({
              type: "ENABLE_NEXT_BUTTON",
            });
          }
        }

        let scrapeComplete = newState.every(
          (studio) => studio.status == "done"
        );

        if (scrapeComplete) {
          // Set retryScrapeCount to max to prevent retry when reports are deleted
          retryScrapeCount.current = retryScrapeMax;
        }
        return newState;

      case "UPDATE_DATES":
        // Add implementation for "UPDATE_DATES" action
        return state;
      case "RESET_STATUS":
        // Implement the logic to reset the status
        // For example, you can set the state back to the initial state
        // or define a specific reset state
        const resetState = {
          // Define the structure of your reset state
          // Example:
          // key: '',
          // status: 'idle',
          // error: false,
          // completedReportsCount: 0,
          // reportsCount: 0,
          // reports: [],
          // ... other properties as needed
        };
        return resetState;

      default:
        return state;
    }
  };

  /**
   * A reducer function to handle date range updates in a state management system.
   * 
   * @param {string[]} state - The current state, an array of strings representing the current date range.
   * @param {Object} action - An action object with `type` and `dates` properties.
   * @param {string} action.type - The type of action to be processed by this reducer. Expected to be "UPDATE_DATES".
   * @param {string[]} action.dates - An array containing two date strings in "mm/dd/yyyy" format, representing the new date range.
   * @returns {string[]} - The updated state containing the new date range, or the previous state if the action type is unrecognized.
   */
  const datesReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_DATES":
        console.log(state)
        console.log("UPDATE_DATES: ", action)
        // Update the state with the new date range provided in `action.dates`.
        return action.dates;

      default:
        // If the action type doesn't match any case, return the existing state unchanged.
        return state;
    }
  };

  const scrapeErrorsReducer = (state, action) => {
    let newState;
    switch (action.type) {
      // dispatch({
      //   type: "SET_ALL_ERRORS",
      //   errors: ,
      // })
      case "SET_ALL_ERRORS":
        return action.errors;

      //    dispatch({
      //   type: "ADD_ERROR",
      //   error: ,
      // })
      case "ADD_ERROR":
        newState = [...state];
        newState.push(action.error);
        return newState;

      case "REMOVE_ERROR":
        return action.dates;
    }
  };
  const timeDetailReducer = (state, action) => {
    let newState;
    switch (action.type) {
      case "UPDATE_STATUS":
        newState = action.value;
        // newState[action.studioIndex] = action.value;
        return newState;

      default:
        return state;
    }
  };


  /**
  const [tableData, dispatchTableData] = useReducer(tableDataReducer, initialTableData);

  // Example actions
  const addRow = row => {
    dispatchTableData({ type: 'ADD_ROW', payload: row });
  };

  const updateRow = row => {
    dispatchTableData({ type: 'UPDATE_ROW', payload: row });
  };

  const deleteRow = id => {
    dispatchTableData({ type: 'DELETE_ROW', payload: { id } });
  };

   */
  function tableDataReducer(state, action) {
    switch (action.type) {
      case 'ADD_ROW':
        // Ensure new rows have an ID and merge with existing rows
        const newRows = action.payload.map(row => ({
          ...row,
          id: row.id || Date.now() + Math.random()  // Ensure each row has a unique ID
        }));

        // Combine with existing rows and sort by the timestamp
        const combinedRows = [...state.rows, ...newRows];
        combinedRows.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));  // Sort rows by timestamp descending

        return { ...state, rows: combinedRows };

      case 'UPDATE_ROW':
        const updatedRows = state.rows.map(row =>
          row.id === action.payload.id ? { ...row, ...action.payload } : row
        );
        return { ...state, rows: updatedRows };

      case 'DELETE_ROW':
        return {
          ...state,
          rows: state.rows.filter(row => row.id !== action.payload.id)
        };
      case 'SET_ROWS':
        // Make sure to correctly format the state to include the rows property
        return { ...state, rows: action.payload, loading: false }; // Correctly replacing all rows
      case 'SET_LOADING':
        // Make sure to correctly format the state to include the rows property
        return { ...state, loading: action.payload }; // Correctly replacing all rows

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }




  const initialTableData = {
    rows: []
  };
  const [tableData, dispatchTableData] = useReducer(
    tableDataReducer,
    initialTableData
  );
  // Example actions
  const addRow = row => {
    // Handle both single row and multiple row additions
    const payload = Array.isArray(row) ? row.map(item => ({
      ...item,
      id: item.id || Date.now() + Math.random() // Ensure unique ID for each item
    })) : { ...row, id: row.id || Date.now() };

    dispatchTableData({ type: 'ADD_ROW', payload });
  };

  const updateRow = row => {
    // Update a row by ID. It assumes that 'row' includes an 'id' and the fields to be updated.
    dispatchTableData({ type: 'UPDATE_ROW', payload: row });
  };

  const deleteRow = id => {
    // Delete a row by ID
    dispatchTableData({ type: 'DELETE_ROW', payload: { id } });
  };

  const setRows = rows => {
    // Ensure each row has a unique ID
    const payload = rows.map(row => ({
      ...row,
      id: row.id || Date.now() + Math.random(), // Generate an ID if one isn't provided
    }));

    // Dispatch the set action with all rows
    dispatchTableData({ type: 'SET_ROWS', payload });
  };




  async function setTableDataWithLatestSessionData() {

    const latestRunDetailsRow = await getLatestRunData(getUID());

    addRow(latestRunDetailsRow);

  }


  const [status, dispatch] = useReducer(statusReducer, []);
  const [scrapeErrors, dispatchScrapeErrors] = useReducer(
    scrapeErrorsReducer,
    []
  );
  const [timeDetailStatus, dispatchTimeDetail] = useReducer(
    timeDetailReducer,
    {}
  );

  function resetScrapeStatusUI() {
    dispatch({
      type: "RESET_STATUS",
    });
  }

  const [selectedDates, dispatchDates] = useReducer(datesReducer, [
    null,
    null,
  ]);

  // Utility Functions

  function getReportIndex(report) {
    return reportCompilerState.scrapeInfo.reportsNeeded.indexOf(report);
  }
  function updateCurrentlySelectedStudios(newVal) {
    currentlySelectedStudiosDispatch({
      type: "SET_SELECTED_STUDIOS",
      studios: newVal,
    });
  }

  // Report Compiler
  function handleAddExcelData(fileId, file) {
    try {
      reportCompilerDispatch({
        type: "ADD_EXCEL_DATA",
        fileId: fileId,
        workbook: file,
        fileName: null,
      });
    } catch (e) {
      console.error("Failed to addExcelData()...", e);
    }
  }

  // ~~~~ NEED TO MAKE SURE isCorrectStudioAndReport WORKS FOR TIME DETAIL DATA THATS IN JSON FORM NOT WORKBOOK FORM

  function isCorrectStudioAndReport(
    workbook,
    targetStudio,
    targetReport,
    reportCompilerState,
    jsonData = false
  ) {
    var isCorrect = true;
    var studio = false;
    var fileType = false;

    if (jsonData) {
      var res = getStudioAndReportTypeFromJSON(jsonData);
      studio = res.studio;
      fileType = res.fileType;
      // ({ studio, fileType } = getStudioAndReportTypeFromJSON(jsonData));
    } else {
      ({ studio, fileType } = getStudioAndReportTypeForWorkbook(
        workbook,
        reportCompilerState
      ));
    }

    // getStudioAndReportTypeForWorkbook is nnot returnning fileType for session payroll
    if (
      (targetStudio !== studio && studio !== "All") ||
      targetReport !== fileType
    ) {
      isCorrect = false;
    }

    return isCorrect;
  }

  // All Lambdas API calls
  const getScrapeResponseFromS3 = async (url) => {
    var scrapeResponse = false;
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      });
      var enc = new TextDecoder("utf-8");
      var responseString = enc.decode(response.data);
      scrapeResponse = JSON.parse(responseString);
    } catch (e) {
      console.error("Failed getting workbook from signed url: ", e);
    }
    return scrapeResponse;
  };
  // const getWorkbookFromS3 = async (url) => {
  //   try {
  //     const response = await axios({
  //       url,
  //       method: "GET",
  //       responseType: "arraybuffer",
  //     });
  //
  //     const workbook = read(response.data, { type: "buffer" });

  //     return workbook;
  //   } catch (error) {
  //     console.error("Failed to get workbook from signed URL: ", error);
  //     throw error;
  //   }
  // };

  const getWorkbookFromS3 = async (url) => {
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "arraybuffer",
      });

      // Derive file name from URL
      // const fileName = url.substring(url.lastIndexOf("/") + 1);
      const fileName = getFileNameFromSignedURL(url);

      const workbook = read(response.data, { type: "buffer" });

      // Return both workbook and filename
      return { workbook, fileName };
    } catch (error) {
      console.error("Failed to get workbook from signed URL: ", error);
      throw error;
    }
  };

  const getClubReadyLoginStudioCookies = async (
    email,
    password,
    studio = false
  ) => {
    const token = await getFirebaseJwtToken();
    const username = await currentUser.uid;
    const myAPI = "paywellAPIResource";
    const path = "/getClubReadyLoginStudioCookies";
    const event = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        email: email,
        password: password,
        studio: studio
      },

      queryStringParameters: {
        email: email,
        studio: studio,
      },
    };
    let res = null;
    await API.post(myAPI, path, event)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("failed scrape api call: ", error);
        res = {
          scrapeSuccess: false,
          error: error,
        };
      });
    return res;
  };

  async function getFileFromStudio(
    report,
    studio,
    startDate,
    endDate,
    // email,
    // password,
    path,
    sessionPayPeriod = false,
    integration = "clubready",
    timeDetail = false
  ) {
    const token = await getFirebaseJwtToken();

    if (
      !report ||
      !studio ||
      !startDate ||
      !endDate ||
      !path ||
      report.trim() === "" ||
      studio.trim() === "" ||
      startDate.trim() === "" ||
      endDate.trim() === "" ||
      path.trim() === ""
    ) {
      console.warn("Missing or empty required parameters");
      return {
        scrapeSuccess: false,
        error: "Missing or empty required parameters",
      };
    } else {
      // Validate startDate
      const parsedStartDate = new Date(startDate);
      if (isNaN(parsedStartDate)) {
        throw new Error("Invalid startDate");
      }

      // Validate endDate
      const parsedEndDate = new Date(endDate);
      if (isNaN(parsedEndDate)) {
        throw new Error("Invalid endDate");
      }

      // If report is booking events log, set the start date one day previous
      if (report === "bel") {
        const newStartDate = getPrevDay(parsedStartDate);
        startDate = newStartDate;
      }

      const myAPI = "paywellAPIResource";
      const event = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          report: report,
          studio: isAccountingReports ? false : studio,
          startDate: startDate,
          endDate: endDate,
          timeLimitInMinutes: 10,
          sessionPayPeriod,
          integration,
          timePunchDetail: timeDetail,
        },
        queryStringParameters: {
          report: report,
          studio: isAccountingReports ? false : studio,
          startDate: startDate,
          endDate: endDate,
          timeLimitInMinutes: 10,
          sessionPayPeriod,
          integration,
          timePunchDetail: timeDetail,
        },
      };

      let res = null;
      try {
        res = await limiter.schedule(() => API.post(myAPI, path, event));
      } catch (error) {
        console.error("Failed scrape API call:", error);
        res = {
          scrapeSuccess: false,
          error: error,
        };
      }

      return res;
    }
  }

  async function getStudioStaffTimeClockInfo(
    studio,
    startDate,
    endDate,
    studioIndex,
    reportIndex
  ) {
    const token = await getFirebaseJwtToken();

    const myAPI = "paywellAPIResource";
    const path = "/getAllStaffTimeClockInfo";
    const event = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        report: "n/a",
        studio: studio,
        startDate: startDate,
        endDate: endDate,
      },

      queryStringParameters: {
        report: "n/a",
        studio: studio,
        startDate: startDate,
        endDate: endDate,
        integration: "clubready"
      },
    };
    let res = null;
    await API.post(myAPI, path, event)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_REPORT_VALUE",
          studioIndex: studioIndex,
          reportIndex: reportIndex,
          key: "status",
          value: "error",
        });

        res = {
          scrapeSuccess: false,
          error: error,
        };
      });
    return res;
  }

  async function getSelectedStudiosTimeClockInfo(startDate, endDate) {
    var allStudiosTimePunchDetailInfo = [];
    let studiosWithoutAccount = findStudiosWithoutAccount(
      currentlySelectedStudios,
      scrapeAccounts.logins
    );
    let studiosWithAccounts = currentlySelectedStudios.filter(
      (studioName) => !studiosWithoutAccount.includes(studioName)
    );

    allStudiosTimePunchDetailInfo = studiosWithAccounts.map(
      async (studio, i) => {
        const studioIndex = currentlySelectedStudios.indexOf(studio);
        let accountForStudio = getAccountFromStudioName(
          studio,
          scrapeAccounts.logins
        )[0];

        return await getStudioStaffTimeClockInfo(
          studio,
          startDate,
          endDate,
          accountForStudio.email,
          accountForStudio.password,
          studioIndex,
          report
        );
      }
    );
    return await Promise.all(allStudiosTimePunchDetailInfo);
  }

  // async function getSingleStaffTimeClockData(
  //   report,
  //   studio,
  //   startDate,
  //   endDate,
  //   email,
  //   password,
  //   staffInfo,
  //   studioIndex,
  //   reportIndex
  // ) {
  //   const token = await getFirebaseJwtToken();

  //   const username = await currentUser.uid;

  //   const myAPI = "paywellAPIResource";
  //   const path = "/getSingleStaffTimeClockData";
  //   const event = {
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     },
  //     body: {
  //       report: report,
  //       studio: studio,
  //       startDate: startDate,
  //       endDate: endDate,
  //       staffInfo: JSON.stringify(staffInfo), // maybe sensitive credentials/encrypt it
  //     },
  //     queryStringParameters: {
  //       report: report,
  //       studio: studio,
  //       startDate: startDate,
  //       endDate: endDate,
  //       staffInfo: JSON.stringify(staffInfo), // maybe sensitive credentials/encrypt it
  //     },
  //   };

  //   let res = null;
  //   try {
  //     // Throttle the API request using the rate limiter
  //     await limiter.schedule(() => {
  //       res = API.post(myAPI, path, event)
  //         .then((response) => {
  //           res = response;

  //           if (res.hasOwnProperty("error")) {
  //             updateStatus(
  //               response,
  //               "error",
  //               staffInfo,
  //               studio,
  //               studioIndex,
  //               reportIndex
  //             );
  //           } else {
  //             updateStatus(
  //               response,
  //               "done",
  //               staffInfo,
  //               studio,
  //               studioIndex,
  //               reportIndex
  //             );
  //           }

  //           currentStaffCount++;
  //           return res;
  //         })
  //         .catch((error) => {
  //           console.error(
  //             `  ${studio} -> ${staffInfo.FirstName} ${staffInfo.LastName} -> FAILED LAMBDA API REQUEST /getSingleStaffTimeClockData: ${error}`
  //           );

  //           res = {
  //             scrapeSuccess: false,
  //             error: error,
  //           };
  //           updateStatus(
  //             res,
  //             "error",
  //             staffInfo,
  //             studio,
  //             studioIndex,
  //             reportIndex
  //           );
  //           // dispatch({
  //           //   type: "UPDATE_REPORT_VALUE",
  //           //   studioIndex: studioIndex,
  //           //   reportIndex: reportIndex,
  //           //   key: "status",
  //           //   value: "error",
  //           // });

  //           // Time Detail Error for All Studios
  //           // currentlySelectedStudios.forEach((studio, i) => {
  //           //     dispatch({
  //           //         type: "UPDATE_REPORT_VALUE",
  //           //         studioIndex: i,
  //           //         reportIndex:
  //           //             reportCompilerState.scrapeInfo.reportsNeeded.indexOf(
  //           //                 "timeDetailData"
  //           //             ),
  //           //         key: "status",
  //           //         value: "error",
  //           //     });
  //           // });  DONT NEED FOR TESTING
  //           return res;
  //         });
  //     });

  //     // Make the API call using Amplify's API class
  //     // res = await API.post(myAPI, path, event);

  //     // Rest of your code...
  //     // Handle the response and any further processing
  //     // ...
  //   } catch (error) {
  //     console.error(
  //       "Failed rate limiter for time detail scrape api call: ",
  //       error
  //     );
  //     res = {
  //       scrapeSuccess: false,
  //       error: error,
  //     };
  //     updateStatus(res, "error", staffInfo, studio, studioIndex, reportIndex);
  //   }

  //   return res;
  // }

  async function getTimeClockDataFromStudio(
    studio,
    startDate,
    endDate,
    // email,
    // password,
    staffInfo,
    studioIndex,
    reportIndex,
    reportCompilerState,
    sessionPayPeriod = false
  ) {
    const token = await getFirebaseJwtToken();

    const username = await currentUser.uid;

    const myAPI = "paywellAPIResource";
    const path = "/getTimeClockDataFromStudioTest";
    const event = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        studio: studio,
        startDate: startDate,
        endDate: endDate,
        sessionPayPeriod: sessionPayPeriod,
        staffInfo: JSON.stringify(staffInfo),
        integration: "clubready"
      },
      queryStringParameters: {
        studio: studio,
        startDate: startDate,
        endDate: endDate,
        sessionPayPeriod: sessionPayPeriod,
        integration: "clubready"
      },
    };

    let res = null;
    try {
      // Throttle the API request using the rate limiter
      await limiter.schedule(() => {
        res = API.post(myAPI, path, event)
          .then((response) => {
            res = response;

            if (res.hasOwnProperty("result")) {
              res = res.result;
            } else if (res.hasOwnProperty("presignedUrl")) {
              // get data from presigned url
              console.warn("get time punch from s3 signed url");
            }

            var missingStaff = missingTimeDetailStaff(staffInfo, res);
            if (missingStaff.length > 0) {
              // retry
              console.warn("MISSING STAFF: ", missingStaff);
              dispatch({
                type: "UPDATE_REPORT_VALUE",
                studioIndex: studioIndex,
                reportIndex: reportIndex,
                key: "status",
                value: "error",
              });
            } else {
              dispatch({
                type: "UPDATE_REPORT",
                reportObj: {
                  status: "done",
                  fileType: "time",
                  fileName: `Time Punch Detail ${startDate} - ${endDate}`,
                  fileIndex: reportIndex,
                  studioIndex: studioIndex,
                  key: "time",
                  workbook: response,
                },
              });

              reportCompilerDispatch({
                type: "COMPILE_TIME_PUNCH_DETAIL_DATA",
                studioId: `time${studioIndex + 1}`,
                timeObjects: response,
              });
            }

            return response;
          })
          .catch((error) => {
            console.error(
              `  ${studio} -> FAILED LAMBDA API REQUEST /getTimeClockDataFromStudioTest: ${error}`
            );
            dispatch({
              type: "UPDATE_REPORT_VALUE",
              studioIndex: studioIndex,
              reportIndex: reportIndex,
              key: "status",
              value: "error",
            });
            res = error;
            return error;
          });
      });
    } catch (error) {
      console.error(
        "Failed rate limiter for time detail scrape api call: ",
        error
      );
      res = error;
      dispatch({
        type: "UPDATE_REPORT_VALUE",
        studioIndex: studioIndex,
        reportIndex: reportIndex,
        key: "status",
        value: "error",
      });
    }

    return res;
  }

  // Define a new function to check if the file exists
  async function checkFileExists(
    report,
    studio,
    startDate,
    endDate,
    username,
    timeLimitInMinutes = 10,
    sessionPayPeriod = false
  ) {
    const token = await getFirebaseJwtToken();

    if (report === "bel") {
      startDate = getPrevDay(startDate);
    }

    const myAPI = "paywellAPIResource";
    const event = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        report: report,
        studio: studio,
        startDate: startDate,
        endDate: endDate,
        // username: username, // Admin only
        timeLimitInMinutes: timeLimitInMinutes,
        sessionPayPeriod,
      },
      queryStringParameters: {
        report: report,
        studio: studio,
        startDate: startDate,
        endDate: endDate,
        // username: username, // Admin only
        timeLimitInMinutes: timeLimitInMinutes,
        sessionPayPeriod,
      },
    };

    let res = null;
    try {
      res = await API.post(myAPI, "/checkS3FileExists", event);
    } catch (error) {
      console.warn("Failed to check file existence:", error);

      return res;
    }

    return res;
  }


  async function checkReportExists(
    studioName,
    studioIndex,
    reportIndex,
    report,
    reportCompilerState,
    sessionPayPeriod = false
  ) {
    dispatch({
      type: "UPDATE_STUDIO_VALUE",
      studioIndex: studioIndex,
      key: "status",
      value: "loading",
    });
    dispatch({
      type: "UPDATE_REPORT_VALUE",
      studioIndex: studioIndex,
      reportIndex: reportIndex,
      key: "status",
      value: "loading",
    });
    var data;
    const uid = await currentUser.uid;
    let checkResponse = await checkFileExists(
      reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
      studioName,
      selectedDates[0],
      selectedDates[1],
      uid,
      sessionPayPeriod
    );
    // If the file exists, use the presigned URL
    if (checkResponse && checkResponse.hasOwnProperty("presignedUrl")) {
      data = checkResponse;
    } else {
      console.warn(
        `${studioName.toUpperCase()} report ${reportCompilerState.scrapeInfo.reportsNeeded[
          reportIndex
        ].toUpperCase()} ${checkResponse && checkResponse.hasOwnProperty("error")
          ? checkResponse.error
          : "does not already exist"
        }`
      );

      // If the file doesn't exist, proceed with the current getFileFromStudio call
      // data = await getFileFromStudio(
      //   reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
      //   studioName,
      //   selectedDates[0],
      //   selectedDates[1],
      //   accountForStudio.email,
      //   accountForStudio.password,
      //   "/getReportApiEndpoint"
      // );
    }

    /**** NEW SCRAPE METHOD  ****/
    if (data.hasOwnProperty("presignedUrl")) {
      let workbook = false;
      let fileName = false;
      if (data.result?.hasOwnProperty("workbook")) {
        workbook = data.result.workbook;
        fileName = data?.result?.uploadResponse?.Key?.substring(
          data?.result?.uploadResponse?.Key?.lastIndexOf("/") + 1
        );
      } else {
        ({ workbook, fileName } = await getWorkbookFromS3(data.presignedUrl));
      }

      if (
        isCorrectStudioAndReport(
          workbook,
          studioName,
          report,
          reportCompilerState
        )
      ) {
        dispatch({
          type: "UPDATE_REPORT",

          reportObj: {
            status: "done",
            fileType: report,
            fileName: fileName ? fileName : report,
            fileIndex: reportIndex,
            studioIndex: studioIndex,
            key: report,
            workbook: workbook,
          },
        });

        handleAddExcelData(report + (studioIndex + 1) + "file", workbook);
      } else {
        dispatch({
          type: "UPDATE_REPORT_VALUE",
          studioIndex: studioIndex,
          reportIndex: reportIndex,
          key: "status",
          value: "error",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_REPORT_VALUE",
        studioIndex: studioIndex,
        reportIndex: reportIndex,
        key: "status",
        value: "error",
      });
    }

    dispatch({
      type: "UPDATE_STUDIO_PROGRESS",
    });
    return true;
  }



  async function checkReportsExists(
    studio,
    studioIndex,
    reportCompilerState,
    sessionPayPeriod = false
  ) {
    return reportCompilerState.scrapeInfo.reportsNeeded.map(
      async (report, reportIndex) => {
        return await checkReportExists(
          studio,
          studioIndex,
          reportIndex,
          report,
          reportCompilerState,
          sessionPayPeriod
        );
      }
    );
  }


  async function checkStudioExists(studio, studioIndex, reportCompilerState) {
    return await checkReportsExists(studio, studioIndex, reportCompilerState);
  }




  async function scrapeStudiosSequentially(studios) {
    try {
      for (let studioIndex = 0; studioIndex < studios.length; studioIndex++) {
        await scrapeStudio(studios[studioIndex], studioIndex);
      }
    } catch (e) {
      console.error("Failed scraping studios: ", e);
    }
  }

  async function checkStudiosExistsSequentially(studios) {
    try {
      for (let studioIndex = 0; studioIndex < studios.length; studioIndex++) {
        await checkStudioExists(studios[studioIndex], studioIndex);
      }
    } catch (e) {
      console.error("Failed scraping studios: ", e);
    }
  }

  async function retryAllFailedReports() {
    await Promise.all([
      status.forEach((studio, studioIndex) => {
        const studioStatus = studio["status"];

        if (studioStatus !== "done" && studioStatus !== "loading") {
          studio.reports.forEach((report, reportIndex) => {
            const reportStatus = report["status"];
            if (reportStatus !== "done") {
              scrapeReport(
                studio.key,
                studioIndex,
                reportIndex,
                report.key,
                reportCompilerState
              );
            }
          });
        }
      }),
    ]);
  }


  async function retryReport(reportObj, independentDates = false) {


    const { fileIndex, fileType, studioIndex } = reportObj;
    await Promise.all([
      scrapeReport(
        currentlySelectedStudios[studioIndex],
        studioIndex,
        fileIndex,
        fileType,
        reportCompilerState,
        independentDates
      ),
    ]);


  }


  function getErrorElements(obj) {
    const keys = Object.keys(obj);

    const errorElements = keys.reduce((acc, key) => {
      if (obj[key].status === "error") {
        acc.push(obj[key].info);
      }
      return acc;
    }, []);

    return errorElements;
  }
  useEffect(() => {
    if (stepperState.activeStep == 2 && Object.keys(status).length > 0) {
      let scrapeComplete = status.every((studio) => studio.status == "done");
      if (
        !scrapeComplete &&
        !isScrapeLoading &&
        retryScrapeCount.current < retryScrapeMax
      ) {
        retryScrapeCount.current = retryScrapeCount.current + 1;
        retryAllFailedReports();
      }
      if (scrapeComplete && minimumFilesUploaded(reportCompilerState)) {
        retryScrapeCount.current = retryScrapeMax;

        dispatchStepper({ type: "ENABLE_NEXT_BUTTON" });
      }
    }
  }, [isScrapeLoading]);


  useEffect(() => {
    if (stepperState.previousStep === 1 && stepperState.activeStep === 2) {
      dispatch({
        type: "RESET_STATUS",
      });
    }
  }, [stepperState.previousStep, stepperState.activeStep]); // Include all relevant parts of stepperState in the dependency array



  async function getPayrollHistory() {
    dispatchTableData({ type: 'SET_LOADING', payload: true });
    const allRows = await getAllUserPayrollRows(`${getUID()}/`, 10);
    setRows(allRows)
    return allRows;
  }



  function initStatus(
    studioTimePunchDetailInfo,
    studio,
    studioIndex,
    reportIndex
  ) {
    let studioStaff = { status: "loading" };
    studioTimePunchDetailInfo?.forEach((staff) => {
      let name = `${staff.FirstName} ${staff.LastName}`;
      let initStaff = { status: "loading", data: null, info: staff };

      studioStaff[name] = initStaff;
    });

    //   dispatchTimeDetail({
    //   type: "UPDATE_STATUS",
    //   key: "status",
    //   value: timeClockStatus,
    // });

    dispatch({
      type: "UPDATE_REPORT",
      reportObj: {
        status: "loading",
        fileType: "time",
        fileName: "ClubReady Time Punch Detail",
        fileIndex: reportIndex,
        studioIndex: studioIndex,
        key: "time",
        workbook: studioStaff,
      },
    });
  }
  function getStudioStatus(obj) {
    let hasError = false;
    let hasLoading = false;

    for (let key in obj) {
      if (key === "status") {
        continue;
      }

      const status = obj[key].status;

      if (status === "error") {
        hasError = true;
      } else if (status === "loading") {
        hasLoading = true;
      }
    }

    if (hasLoading) {
      return "loading";
    } else if (hasError) {
      return "error";
    } else {
      return "done";
    }
  }
  function parseReports(newState) {
    var studioTimeDetailData = {};

    newState.forEach((studio, i) => {
      studio.reports.forEach((report) => {
        if (report.fileType === "time") {
          let workbook = JSON.parse(JSON.stringify(report.workbook));
          delete workbook.status;
          studioTimeDetailData["time" + (i + 1)] = workbook;
        }
      });
    });

    return studioTimeDetailData;
  }
  function updateStatus(
    data,
    newStaffStatus,
    staffInfo,
    studio,
    studioIndex,
    reportIndex
  ) {
    let name = `${staffInfo.FirstName} ${staffInfo.LastName}`;
    let updatedStaff = { status: newStaffStatus, data: data, info: staffInfo };

    dispatch({
      type: "UPDATE_SCRAPE_STATUS_PROPERTY",
      path: `${studioIndex}.reports.${reportIndex}.workbook.${name}`,
      value: updatedStaff,
      replace: true,
    });
  }

  async function scrapeSelectedStudios(
    currentlySelectedStudios,
    scrapeAccounts,
    reportCompilerState,
    sync = true,
  ) {
    let studiosWithoutAccount = findStudiosWithoutAccount(
      currentlySelectedStudios,
      scrapeAccounts.logins
    );
    let studiosWithAccounts = currentlySelectedStudios.filter(
      (studioName) => !studiosWithoutAccount.includes(studioName)
    );

    currentlySelectedStudiosDispatch({
      type: "SET_SELECTED_STUDIOS",
      studios: studiosWithAccounts,
    });

    try {
      if (sync) {
        // await checkStudiosExists(studiosWithAccounts, currentlySelectedStudios, scrapeAccounts);
        // await scrapeStudios(studiosWithAccounts, currentlySelectedStudios, scrapeAccounts);
      } else {
        await Promise.all(
          studiosWithAccounts.map(async (studioName, studioIndex) => {
            await scrapeStudio(
              studioName,
              studioIndex,
              currentlySelectedStudios,
              scrapeAccounts,
              reportCompilerState
            );
          })
        );
      }

      // Resolve the promise when all inner operations are completed
      return Promise.resolve();
    } catch (error) {
      // Handle any errors that may occur during scraping.
      console.error("Error while scraping studios:", error);
      // Reject the promise in case of an error
      return Promise.reject(error);
    }
  }

  async function scrapeStudio(
    studioName,
    studioIndex,
    currentlySelectedStudios,
    scrapeAccounts,
    reportCompilerState
  ) {
    try {
      let accountForStudio = getAccountFromStudioName(
        studioName,
        scrapeAccounts.logins
      )[0];

      await getClubReadyLoginStudioCookies(
        accountForStudio.email,
        accountForStudio.password,
        studioName
      );

      // Instead of returning a resolved promise, await the result of scrapeReports
      await scrapeReports(
        studioName,
        studioIndex,
        currentlySelectedStudios,
        scrapeAccounts,
        reportCompilerState
      );
    } catch (error) {
      console.error("Error while scraping studio:", error);
      // Return a rejected promise to indicate an error
      throw error; // Use throw to propagate the error
    }
  }

  async function scrapeReports(
    studioName,
    studioIndex,
    currentlySelectedStudios,
    scrapeAccounts,
    reportCompilerState
  ) {
    try {
      const reportPromises = reportCompilerState.scrapeInfo.reportsNeeded.map(
        async (report, reportIndex) => {
          // Return and await each promise
          return await scrapeReport(
            studioName,
            studioIndex,
            reportIndex,
            report,
            reportCompilerState
          );
        }
      );

      // Wait for all reportPromises to complete
      await Promise.all(reportPromises);


    } catch (error) {
      console.error("Error while scraping reports:", error);
      // Return a rejected promise to indicate an error
      throw error;
    }
  }


  async function scrapeReport(
    studioName,
    studioIndex,
    reportIndex,
    report,
    reportCompilerState,
    independentDates = false
  ) {
    if (!selectedDatesValid(selectedDates[0], selectedDates[1])) {
      showSnackBar("Please re-enter pay period and retry.", "error");
    } else {
      let sessionPayPeriod = `${formatDate(
        new Date(selectedDates[0])
      )}_${formatDate(new Date(selectedDates[1]))}`;
      let selectedPayPeriod = [...selectedDates];
      if (independentDates) {
        selectedPayPeriod = independentDates;
      }
      try {
        dispatch({
          type: "UPDATE_STUDIO_VALUE",
          studioIndex: studioIndex,
          key: "status",
          value: "loading",
        });
        dispatch({
          type: "UPDATE_REPORT_VALUE",
          studioIndex: studioIndex,
          reportIndex: reportIndex,
          key: "status",
          value: "loading",
        });
        const uid = await currentUser.uid;
        // Then in your function, before calling getFileFromStudio:
        const checkResponsePromise = checkFileExists(
          reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
          studioName,
          selectedPayPeriod[0], // NEED FOR PAYROLL HISTORY RUN
          selectedPayPeriod[1], // NEED FOR PAYROLL HISTORY RUN
          uid,
          10,
          sessionPayPeriod // NEED FOR PAYROLL HISTORY RUN
        );
        // If the file exists, use the presigned URL
        let data;
        const checkResponse = await checkResponsePromise;
        if (checkResponse && checkResponse.hasOwnProperty("presignedUrl")) {
          data = checkResponse;
        } else {
          if (
            report === "time" &&
            reportCompilerState.scrapeInfo.fullNameReportsNeeded.includes(
              "ClubReady Time Punch Detail"
            )
          ) {
            data = await getFileFromStudio(
              // reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
              "time",
              studioName,
              selectedPayPeriod[0],
              selectedPayPeriod[1],
              // accountForStudio.email,
              // accountForStudio.password,
              "/getTimeClockPayrollReport",
              sessionPayPeriod,
              "clubready",
              true
            );

            if (data && data.hasOwnProperty("error")) {
              console.error("Failed /getTimeClockPayrollReport", data);
            } else {
              // Parse the data from the input JSON string
              const jsonData = JSON.parse(data.result.workbook);
              // Create a new workbook
              const workbook = read(jsonData.data, { type: "buffer" });

              data.result.workbook = workbook;
            }
            // Get Time Punch Detail Staff URL's for Studio
            // let studioTimePunchDetailInfo = await getStudioStaffTimeClockInfo(
            //   studioName,
            //   selectedPayPeriod[0],
            //   selectedPayPeriod[1],
            //   // accountForStudio.email,
            //   // accountForStudio.password,
            //   studioIndex,
            //   reportIndex
            // );

            // dispatch({
            //   type: "UPDATE_REPORT",
            //   reportObj: {
            //     status: "loading",
            //     fileType: "time",
            //     fileName: "ClubReady Time Punch Detail",
            //     fileIndex: reportIndex,
            //     studioIndex: studioIndex,
            //     key: "time",
            //     workbook: studioTimePunchDetailInfo,
            //   },
            // });

            // // Get Time Detail Punch Ins/Out for every staff member at studio
            // data = await getTimeClockDataFromStudio(
            //   studioName,
            //   selectedPayPeriod[0],
            //   selectedPayPeriod[1],
            //   // accountForStudio.email,
            //   // accountForStudio.password,
            //   studioTimePunchDetailInfo,
            //   studioIndex,
            //   reportIndex,
            //   reportCompilerState,
            //   sessionPayPeriod
            // );
          } else if (
            report === "time" &&
            reportCompilerState.scrapeInfo.fullNameReportsNeeded.includes(
              "Time Clock Payroll"
            )
          ) {
            data = await getFileFromStudio(
              reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
              studioName,
              selectedPayPeriod[0],
              selectedPayPeriod[1],
              // accountForStudio.email,
              // accountForStudio.password,
              "/getTimeClockPayrollReport",
              sessionPayPeriod
            );

            if (data && data.hasOwnProperty("error")) {
              console.error("Failed /getTimeClockPayrollReport", data);
            } else {
              // Parse the data from the input JSON string
              const jsonData = JSON.parse(data.result.workbook);

              // Create a new workbook
              const workbook = read(jsonData.data, { type: "buffer" });

              data.result.workbook = workbook;
            }
          } else {
            data = await getFileFromStudio(
              reportCompilerState.scrapeInfo.reportsNeeded[reportIndex],
              studioName,
              selectedPayPeriod[0],
              selectedPayPeriod[1],
              // accountForStudio.email,
              // accountForStudio.password,
              "/getReportApiEndpoint",
              sessionPayPeriod
            );
          }
        }
        /**** NEW SCRAPE METHOD  ****/
        if (data && data.hasOwnProperty("presignedUrl")) {
          let workbook = false;
          let jsonData = false;
          let fileName = false;
          // if(report === "time")  {
          //
          // }

          if (data.result?.hasOwnProperty("workbook")) {
            workbook = data.result.workbook;
            fileName = data?.result?.uploadResponse?.Key?.substring(
              data?.result?.uploadResponse?.Key?.lastIndexOf("/") + 1
            );
            if (
              report === "time" &&
              reportCompilerState.scrapeInfo.fullNameReportsNeeded.includes(
                "ClubReady Time Punch Detail"
              )
            ) {
            }
          } else {
            if (
              report === "time" &&
              reportCompilerState.scrapeInfo.fullNameReportsNeeded.includes(
                "ClubReady Time Punch Detail"
              )
            ) {
            }
            ({ workbook, fileName } = await getWorkbookFromS3(data.presignedUrl));
          }

          if (
            report === "time" ||
            isCorrectStudioAndReport(
              workbook,
              studioName,
              report,
              reportCompilerState,
              jsonData
            )
          ) {
            dispatch({
              type: "UPDATE_REPORT",
              reportObj: {
                status: "done",
                fileType: report,
                fileName: fileName ? fileName : report,
                fileIndex: reportIndex,
                studioIndex: studioIndex,
                key: report,
                workbook: workbook,
              },
            });
            if (report == "time") {
              handleAddExcelData(report + (studioIndex + 1) + "file", workbook);
            } else {
              handleAddExcelData(report + (studioIndex + 1) + "file", workbook);
            }
          } else {
            dispatch({
              type: "UPDATE_REPORT_VALUE",
              studioIndex: studioIndex,
              reportIndex: reportIndex,
              key: "status",
              value: "error",
            });
          }
        } else {
          dispatch({
            type: "UPDATE_REPORT_VALUE",
            studioIndex: studioIndex,
            reportIndex: reportIndex,
            key: "status",
            value: "error",
          });
        }
        dispatch({
          type: "UPDATE_STUDIO_PROGRESS",
        });
        allBookingEventFilesUploaded();
        // Return the retrieved data
        return data;
      } catch (error) {
        console.error("Error while scraping report:", error);
        // Return a rejected promise to indicate an error
        throw error;
      }
    }
  }


  /***  USE EXISTING PAYROLL LOGIC ***/
  /** Actually adds the latest run workbook data to compiler */
  async function useLatestRunData(uid, latestSessionPath) {


    const { updatedReportCompilerState, studios, fileRegistry } = await getReportCompilerStateFromLatestSessionSnapshot(latestSessionPath, currentlySelectedStudios, getUrlVariableValue("settingsId"), reportCompilerState);





    const newStatus = updateStudioStatuses(studios, fileRegistry, reportCompilerState);


    dispatch({
      type: "SET_EXISTING_PAYROLL",
      newStatus
    });


    dispatch({ type: "UPDATE_STUDIO_PROGRESS" });



  }






  // Context
  const value = {
    setRows,
    getPayrollHistory,
    resetScrapeStatusUI,
    addRow,
    newPayroll, setNewPayroll,
    existingPayroll, setExistingPayroll,
    useLatestRunData,
    tableData, dispatchTableData,
    getSelectedStudiosTimeClockInfo,

    getStudioStaffTimeClockInfo,
    retryAllFailedReports,
    retryReport,
    scrapeSelectedStudios,
    selectedDates,
    dispatchDates,
    timeDetailStatus,
    dispatchTimeDetail,
    grabReportsButtonDisabled,
    setGrabReportsButtonDisabled,
    handleAddExcelData,
    getScrapeResponseFromS3,
    getClubReadyLoginStudioCookies,
    getFileFromStudio,
    getReportIndex,

    updateCurrentlySelectedStudios,
    allBookingEventFilesUploaded,
    accountingStudios,
    inputFileUploaded,
    setInputFileUploaded,
    isCorrectStudioAndReport,
    status,
    dispatch,
    scrapeErrors,
    dispatchScrapeErrors,
    currentlySelectedStudios,
    currentlySelectedStudiosDispatch,
    setIsContinueButtonDisabled,
    isContinueButtonDisabled,
  };

  return (
    <StudioScrapeStatusContext.Provider value={value}>
      {children}
    </StudioScrapeStatusContext.Provider>
  );
}
