import React from "react";
import { API } from "aws-amplify";
// import * as XLSX from 'xlsx';
import XLSX from "@sheet/core";

import { getFirebaseJwtToken } from "./tokenUtility";

import { useLocation } from "react-router-dom";
// import { BooleanToggle } from "./QuestionObjects/BooleanToggle";
import {
  convertToBoolean,
  convertToNumber,
  convertToArray,
} from "./QuestionObjects/GeneralTypeConversions.js";

import { AgreementPriceSettings } from "./QuestionObjects/AgreementPriceSettings.js";
import { BooleanToggle } from "./QuestionObjects/BooleanToggle.js";
import { CatchAllClassLogic } from "./QuestionObjects/CatchAllClassLogic.js";
import { CatchAllCommissionLogic } from "./QuestionObjects/CatchAllCommissionLogic.js";
import { CatchAllTimeLogic } from "./QuestionObjects/CatchAllTimeLogic.js";
import { ClassAttributeList } from "./QuestionObjects/ClassAttributeList.js";
import { ClassBucket } from "./QuestionObjects/ClassBuckets.js";
import { CommissionBucket } from "./QuestionObjects/CommissionBuckets.js";
import { HoursVsClassFlag } from "./QuestionObjects/HourVsClassFlag.js";
import { InputBox } from "./QuestionObjects/InputBox.js";
import { ProcessorConfig } from "./QuestionObjects/ProcessorConfig.js";
import { Column } from "./QuestionObjects/ProcessorConfig.js";
import { StaffAttribute } from "./QuestionObjects/ProcessorConfig.js";
import { ExcelAttribute } from "./QuestionObjects/ProcessorConfig.js";
import { RowAttribute } from "./QuestionObjects/ProcessorConfig.js";
// import Studio from "../components/Studio"; ~~~~ check with scott
import { AgreementEvent } from "./ReportCompiler/classes/AgreementEvent.js";
import { BookingEvent } from "./ReportCompiler/classes/BookingEvent.js";
import { ClassAttributes } from "./ReportCompiler/classes/BookingEvent.js";
import { EventAttributes } from "./ReportCompiler/classes/BookingEvent.js";
import { CellReference } from "./ReportCompiler/classes/CellReference.js";
import { CommissionObject } from "./ReportCompiler/classes/CommissionObject.js";
import { DetailReferenceBox } from "./ReportCompiler/classes/DetailReferenceBox.js";
import { Member } from "./ReportCompiler/classes/Member.js";
import { PayWellStaff } from "./ReportCompiler/classes/PayWellStaff.js";
import { RetailEvent } from "./ReportCompiler/classes/RetailEvent.js";
import { SessionPayrollClassEvent } from "./ReportCompiler/classes/SessionPayrollClassEvent.js";
import { TimeEvent } from "./ReportCompiler/classes/TimeEvent.js";
// Classes
// I had to put all classes in here becyase apparently using eval() on a imported class doesnt work

export class ReportData {
  constructor(isCorrectFile, fileDataArray) {
    this.correctFileType = isCorrectFile;
    this.dataArray = fileDataArray;
    this.payPeriod = null;
    this.classesInFile = null;
    this.staffInFile = null;
  }
}

export class FullClassPaySpecifications {
  constructor(valid, statuses) {
    this.valid = false;
    this.attributes = ["Cancelled Outside Policy", "Rescheduled By Admin"];
    this.addValid(valid);
    this.addAttributes(statuses);
    this.hoursCancelledWithin = -1;
  }

  addValid(valid) {
    let booleanValid = convertToBoolean(valid);
    if (booleanValid !== null) {
      this.valid = booleanValid;
      return;
    }
    this.errorMessage = "valid was not of type boolean";
  }

  addAttributes(statuses) {
    let statusesArray = convertToArray(statuses);
    if (statusesArray !== null) {
      this.valid = statusesArray;
      return;
    }
    this.errorMessage = "class statuses could not be read as type array";
  }

  addStatusesCancelledWithin(hours) {
    let hoursInt = convertToNumber(false, hours);
    if (hoursInt !== null) {
      this.hoursCancelledWithin = hoursInt;
      return;
    }
    this.errorMessage = "hours was not of type integer";
  }
}
export class PayPeriodInfo {
  constructor(frequency) {
    this.frequency = frequency;
    this.exampleStartDate = null;
    this.exampleEndDate = null;
  }

  setStartDate(date) {
    if (typeof date === "string") {
      this.exampleStartDate = createDateFromString(date);
      return;
    } else if (date instanceof Date) {
      this.exampleStartDate = date;
      return;
    }
    this.errorMessage = "start value was not of type date";
  }

  setEndDate(date) {
    if (typeof date === "string") {
      this.exampleEndDate = createDateFromString(date);
      return;
    } else if (date instanceof Date) {
      this.exampleEndDate = date;
      return;
    }
    this.errorMessage = "end value was not of type date";
  }

  findMostRecentPayPeriod() {
    let today = new Date();
    //complete later
  }
}
export class SelectorBox {
  constructor(optionArray) {
    this.options = {};
    let convertedOptions = convertToArray(optionArray);
    if (convertedOptions !== null) {
      for (let i = 0; i < convertedOptions.length; i++) {
        this.options[convertedOptions[i]] = false;
      }
    }
  }

  selectOption(option) {
    if (this.options.hasOwnProperty(option)) {
      this.options[option] = true;
    } else {
      this.errorMessage = "No property: " + option;
    }
  }
}

// Report Compiler Classes
export class ClassEvent {
  constructor(name, instructor, location, date, sessionLength) {
    this.type = "None";
    this.name = name;
    this.secondaryName = "";
    this.instructor = instructor;
    this.location = location;
    this.date = date;
    this.attendeeCount = 0;
    this.pay = null;
    this.sessionLength = sessionLength;
    this.comment = [];
    this.attendeeList = [];

    this.addedToExcel = false;
  }

  getClassTime() {
    let hours = this.date.getHours();
    let minutes = this.date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + ":00 " + ampm;
    return strTime;
  }

  commentsToString() {
    let commentStr = "";
    for (let i = 0; i < this.comment.length; i++) {
      if (this.comment[i].length > 0) {
        commentStr = commentStr + " / " + this.comment[i];
      }
    }
    if (commentStr.length === 0) {
      return "";
    }
    return commentStr.substring(3);
  }
}
export class ClassAttendee {
  constructor(name, loggedBy, loggedTime, bookingEventType, bookingStatus) {
    this.name = name;
    this.loggedBy = loggedBy;
    this.loggedTime = loggedTime;
    this.bookingEventType = bookingEventType;
    this.bookingStatus = bookingStatus;

    this.signedUpAfterSession = null;

    this.completed = false;
  }

  setSignedUpAfterSession(outcome) {
    this.signedUpAfterSession = outcome;
  }

  toString() {
    return (
      "Name: " +
      this.name +
      "\nLogged By: " +
      this.loggedBy +
      "\nLogged Time: " +
      this.loggedTime
    );
  }
}
export class ClassTypeRequirements {
  constructor(type, correctTypes, incorrectTypes) {
    this.type = type;
    this.correctTypes = correctTypes;
  }
}
export class CommissionDetailReferenceBox {
  constructor(type, staffName, staffType, ref) {
    this.type = type;
    this.staffName = staffName;
    this.staffType = staffType;
    this.ref = ref;
  }
}
export class TimeDetailReference {
  constructor(staffName, location, row) {
    this.staffName = staffName;
    this.location = location;
    this.row = row;
  }
}

const classMap = {
  // ClassCreation,
  TimeEvent,
  ReportData,
  AgreementPriceSettings,
  BooleanToggle,
  CatchAllClassLogic,
  CatchAllCommissionLogic,
  CatchAllTimeLogic,
  ClassAttributeList,
  ClassBucket,
  CommissionBucket,
  FullClassPaySpecifications,
  HoursVsClassFlag,
  InputBox,
  PayPeriodInfo,
  ProcessorConfig,
  Column,
  StaffAttribute,
  ExcelAttribute,
  RowAttribute,
  SelectorBox,
  // Studio, ~~~~ check with scott
  AgreementEvent,
  BookingEvent,
  ClassAttributes,
  EventAttributes,
  CellReference,
  ClassEvent,
  ClassAttendee,
  ClassTypeRequirements,
  CommissionDetailReferenceBox,
  CommissionObject,
  DetailReferenceBox,
  Member,
  PayWellStaff,
  RetailEvent,
  SessionPayrollClassEvent,
};
function createInstanceByClassName(className) {
  if (className in classMap) {
    const classConstructor = classMap[className];
    return new classConstructor();
  } else {
    throw new Error(`Class '${className}' not found.`);
  }
}

// Functions
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Parses through an object and returns all objects that have the property called "status" at the top level.
 * @param {object} obj - The object to be parsed.
 * @returns {array} - An array containing all objects that have the "status" property.
 */
export function findObjectsWithProperty(obj, property) {
  let result = [];

  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null && // Add this check to ensure obj[key] is not null
      typeof obj[key] === "object" &&
      obj[key].hasOwnProperty(property) &&
      !isNullOrEmpty(obj[key][property])
    ) {
      result.push(obj[key]);
    }
  }

  return result;
}

/**
 * Checks if a string is null or empty.
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is null or empty, otherwise returns false.
 */
function isNullOrEmpty(str) {
  return str === null || str.trim() === "";
}

/**
 * Adds or updates a variable with its value in the URL without refreshing the page.
 * @param {string} variable - The variable name to add or update in the URL.
 * @param {string} value - The corresponding value of the variable.
 */
export function addOrUpdateUrlVariable(variable, value) {
  // Get the current URL
  const currentUrl = window.location.href;

  // Check if the variable already exists in the URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set(variable, value);

  // Create the new URL with the updated variable value
  const newUrl = currentUrl.split("?")[0] + "?" + urlSearchParams.toString();

  // Push the new URL to the browser history without refreshing the page
  window.history.pushState({ path: newUrl }, "", newUrl);
}

/**
 * Retrieves the value of a URL variable.
 * @param {string} variable - The variable name to retrieve its value.
 * @returns {string|null} - The value of the URL variable, or null if it doesn't exist.
 */
export function getUrlVariableValue(variable) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(variable);
}

/**
 * Checks if a property exists in an object.
 * @param {object} object - The object to check for the property.
 * @param {string} property - The property to check for existence.
 * @returns {boolean} - Returns true if the property exists, false otherwise.
 */
export function checkObjectProperty(object, property) {
  return Object.prototype.hasOwnProperty.call(object, property);
}

// export function updateObject(obj, path, value) {
//   const keys = path.split(".");
//   let currentObj = obj;

//   for (let i = 0; i < keys.length - 1; i++) {
//     const key = keys[i];
//     if (!currentObj.hasOwnProperty(key)) {
//       currentObj[key] = {};
//     }
//     currentObj = currentObj[key];
//   }

//   currentObj[keys[keys.length - 1]] = value;
//   return obj;
// }

export function updateObject(obj, path, value, replace = true) {
  const keys = path.split(".");
  let currentObj = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!currentObj.hasOwnProperty(key)) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  if (replace) {
    currentObj[keys[keys.length - 1]] = value;
  } else {
    const key = keys[keys.length - 1];
    if (!currentObj.hasOwnProperty(key)) {
      currentObj[key] = value;
    } else if (Array.isArray(currentObj[key])) {
      currentObj[key] = currentObj[key].concat(value);
    } else {
      currentObj[key] = [currentObj[key], value];
    }
  }

  return obj;
}

function isFunctionString(str) {
  return /^function\s*\(/.test(str);
}

function createFunctionFromSource(source) {
  return eval(`(${source})`);
}
/**
 * A custom serialization function that converts an object with class instances to a plain object with serialized versions of the class instances.
 *
 * @param {object} obj - The object to serialize.
 * @returns {object} A plain object with serialized versions of the class instances.
 */
// export function serializeObjectWithMethods(obj) {
//   const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
//   Object.entries(obj).forEach(([key, value]) => {
//     // If the property is an instance of a class, replace it with a serialized version of the instance.
//     if (!Array.isArray(value)  ) {
//       if (
//         typeof value === "object" &&
//         value !== null &&
//         value.constructor &&
//         value.constructor.name !== "Object"

//       ) {

//         // Serialize the instance to a plain object.

//         const serializedInstance = {};
//         Object.entries(value).forEach(([key, value]) => {
//           serializedInstance[key] = value;
//         });
//         serializedInstance["__class__"] = value.constructor.name;

//         // Replace the instance with the serialized version.
//         clonedObj[key] = serializedInstance;

//       }
//     }
//     // If the property is an object or array, serialize it recursively.
//     if (typeof obj[key] === "object" && obj[key] !== null) {
//       clonedObj[key] = serializeObjectWithMethods(clonedObj[key]);
//     }
//   });

//   // Return the cloned object with serialized class instances.
//   return clonedObj;
// }

/**
 * A custom deserialization function that converts a plain object with serialized versions of class instances to an object with proper class instances.
 *
 * @param {object} obj - The object to deserialize.
 * @returns {object} An object with proper class instances.
 */

// export function deserializeObjectWithMethods(obj) {
//   // Clone the input object to avoid modifying the original.
//   const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };

//   // Loop through each property of the cloned object.
//   Object.entries(obj).forEach(([key, value]) => {
//     let instanceCreated = false;
//     // If the property is a serialized instance of a class, replace it with an actual instance of the class.
//     if (!Array.isArray(value)) {
//       if (typeof value === "object" && value !== null && "__class__" in value) {
//         // Get the class name and constructor function.
//         const className = value["__class__"];

//         const classConstructor = eval(className);

//         // Create a new instance of the class and set its properties from the serialized version.
//         const instance = new classConstructor();

//         Object.entries(value).forEach(([key, value]) => {
//           if (key !== "__class__") {
//             instance[key] = value;
//           }
//         });

//         // Replace the serialized instance with the actual instance.
//         clonedObj[key] = instance;

//         instanceCreated = true;
//       }
//     }
//     // If the property is an object or array, deserialize it recursively.
//     if (!instanceCreated && typeof obj[key] === "object" && obj[key] !== null) {
//       clonedObj[key] = deserializeObjectWithMethods(clonedObj[key]);
//     }

//   });

//   // Return the cloned object with deserialized class instances.
//   return clonedObj;
// }

export function serializeObjectWithMethods(obj) {
  const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
  Object.entries(clonedObj).forEach(([key, value]) => {
    if (value instanceof Date) {
      clonedObj[key] = { __date__: value.toISOString() };
    } else if (typeof value === "object" && value !== null) {
      if (value.constructor && value.constructor.name !== "Object") {
        const serializedInstance = serializeObjectWithMethods(value);
        serializedInstance["__class__"] = value.constructor.name;
        clonedObj[key] = serializedInstance;
      } else {
        clonedObj[key] = serializeObjectWithMethods(value);
      }
    }
  });
  return clonedObj;
}

export function deserializeObjectWithMethods(obj) {
  if (typeof obj === "object" && obj !== null) {
    if ("__date__" in obj) {
      return new Date(obj["__date__"]);
    } else if ("__string__" in obj) {
      return obj["__string__"];
    } else if ("__boolean__" in obj) {
      return obj["__boolean__"];
    } else if ("__class__" in obj) {
      const className = obj["__class__"];
      var instance;
      var classConstructor;

      if (className in classMap) {
        instance = createInstanceByClassName(className);
      } else {
        classConstructor = eval(className);
        instance = new classConstructor();
      }
      Object.entries(obj).forEach(([key, value]) => {
        if (key !== "__class__") {
          instance[key] = deserializeObjectWithMethods(value);
        }
      });
      return instance;
    } else {
      const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
      Object.entries(clonedObj).forEach(([key, value]) => {
        clonedObj[key] = deserializeObjectWithMethods(value);
      });
      return clonedObj;
    }
  }
  return obj;
}

export function getObjectLength(obj) {
  return Object.keys(obj).length;
}
// export function pushToCollection(collection, ...values) {
//   if (typeof collection === "object" && getObjectLength(collection) < 1) {
//     return [...values];
//   } else if (Array.isArray(collection)) {
//     return [...collection, ...values];
//   } else if (typeof collection === "object" && collection !== null) {
//     return [
//       ...collection,
//       ...Object.fromEntries(values.map((val) => [val.key, val.value])),
//     ];
//   } else {
//     throw new TypeError("First argument must be an array or an object.");
//   }
// }

export function findStudioNumberFromInputId(fileId) {
  const number = fileId.match(/\d/g);
  if (number === null) {
    return 0;
  } else {
    return number;
  }
}

export function findPayRates(inputFilesArrays, studiosInformation) {
  for (let i = 0; i < inputFilesArrays.TIME.length; i++) {
    if (
      inputFilesArrays?.TIME[i]?.staffName &&
      inputFilesArrays?.TIME[i]?.location &&
      !inputFilesArrays?.TIME[i]?.exception &&
      inputFilesArrays
    ) {
      let payRate = findHourlyPayRate(
        inputFilesArrays.TIME[i].staffName,
        inputFilesArrays.TIME[i].location,
        studiosInformation
      );
      if (payRate !== 0) {
        inputFilesArrays.TIME[i].payRate = payRate;
      }
    } else {
      //console.warn("Undefined Parameter in function");
    }
    if (inputFilesArrays.TIME[i].overrideRate !== undefined) {
      inputFilesArrays.TIME[i].payRate = parseFloat(inputFilesArrays.TIME[i].overrideRate);
    }
  }
  return inputFilesArrays.TIME;
}
export function findHourlyPayRate(name, location, studiosInformation) {
  const instructorPayTable = studiosInformation.staffArray;

  for (let i = 0; i < instructorPayTable.length; i++) {
    if (instructorPayTable[i].isNamed(name)) {
      if (instructorPayTable[i].isLocated(location)) {
        if (location !== "All") {
          let studioHourlyRate = instructorPayTable[i].getHourlyRate(location);

          return parseFloat(studioHourlyRate);
        }

        let firstHourlyRate = instructorPayTable[i].getHourlyRate(
          instructorPayTable[i].getLocations()[0]
        );
        return parseFloat(firstHourlyRate);
      }
    }
  }
  return 0;
}

export function checkLocation(staffObj, location) {
  return (
    location === "Any" || location === "All" || staffObj.isLocated(location)
  );
}

export function findStaffObject(name, reportCompilerState) {
  let staffArray = reportCompilerState.studiosInformation.staffArray;
  for (let i = 0; i < staffArray.length; i++) {
    if (staffArray[i].isNamed(name)) {
      return staffArray[i];
    }
  }

  return false;
}

export function getStaff(staffArray, reportCompilerState) {
  let staffFound = reportCompilerState.studiosInformation["staffArray"];
  if (staffArray.length === 0) {
    let allStaff = staffFound.map((staff) => staff.primaryName);
    return allStaff;
  }
  return staffArray;
}

export function getLocations(locationArray, reportCompilerState) {
  if (locationArray.length === 0) {
    return reportCompilerState.payrollInformation["studiosInInput"];
  }
  return locationArray;
}

export function checkDay(timeException) {
  var today = new Date();
  let day = parseInt(timeException.payLogic.day.replace(/\D/g, ""));
  if (day === null) {
    return true;
  }
  if (timeException.payLogic.sequence === "ANY DATE") {
    return true;
  } else if (timeException.payLogic.sequence === "Before") {
    if (today.getDate() < day) {
      return true;
    }
  } else if (timeException.payLogic.sequence === "After") {
    if (today.getDate() > day) {
      return true;
    }
  }
  return false;
}

function checkIfArrayContainsStaff(staffArray, staffName, reportCompilerState) {
  for (let i = 0; i < staffArray.length; i++) {
    let staffObject = findStaffObject(staffArray[i], reportCompilerState);
    if (staffObject && staffObject.isNamed(staffName)) {
      return true;
    }
  }
  return false;
}

export function checkQualifications(
  timeEvent,
  timeException,
  reportCompilerState
) {
  let staffArray = getStaff(timeException.staff, reportCompilerState);
  let locations = getLocations(timeException.location, reportCompilerState);
  if (timeEvent?.staffName) {
    if (
      !checkIfArrayContainsStaff(
        staffArray,
        timeEvent.staffName,
        reportCompilerState
      )
    ) {
      //if (!staffArray.includes(timeEvent.staffName)) {
      return false;
    }

    if (!locations.includes(timeEvent.location)) {
      return false;
    }
    if (
      timeEvent.description !== timeException.type &&
      timeException.type !== "" &&
      timeException.type !== "Default"
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.hoursWorked) !== parseFloat(timeException.hours) &&
      parseFloat(timeException.hours) !== -1
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.payRate) !== parseFloat(timeException.rate) &&
      parseFloat(timeException.rate) !== -1
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.payTotal) !== parseFloat(timeException.total) &&
      parseFloat(timeException.total) !== -1
    ) {
      return false;
    }
    if (
      timeEvent.comment !== timeException.comment &&
      timeException.comment !== ""
    ) {
      return false;
    }
    if (
      timeException.payLogic.sequence !== "ANY DATE" &&
      !checkDay(timeException)
    ) {
      return false;
    }
  } else {
    console.error("timeEvent is undefined");
  }

  return true;
}

export function getFirstNumericKey(obj) {
  for (var key in obj) {
    if (!isNaN(Number(key))) {
      return key;
    }
  }
  return null; // Return null if no numeric key is found
}

export function removeStringFromArray(array, stringToRemove) {
  const index = array.indexOf(stringToRemove);
  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export function getSecondaryPageFromUrl() {
  // Get the current pathname from the window's location
  const pathname = window.location.pathname;

  // Split the pathname into segments using '/' as the delimiter
  const segments = pathname.split("/");

  // Find the segment following "studio-settings"
  const index = segments.indexOf("studio-setting");
  if (index !== -1 && index < segments.length - 1) {
    // The segment following "studio-settings" is at index + 1
    return segments[index + 1];
  } else {
    return "studios"; // Return null if no segment found after 'studio-settings'
  }
}

export function formatDate(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

export async function uploadFileToS3(
  file,
  studioName,
  uid,
  payPeriodStartDate,
  payPeriodEndDate,
  fileNameWithExtension
) {




  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // Check if 'file' is a real file
    if (!(file instanceof File)) {
      console.error("Invalid file parameter. 'file' must be a File object.");
      return;
    }
    const data = await file.arrayBuffer();
    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const startDate = formatDate(new Date(payPeriodStartDate));
      const endDate = formatDate(new Date(payPeriodEndDate));
      const payPeriod = startDate + "_" + endDate;

      const myAPI = "paywellAPIResource";
      const path = "/createPresignedPost";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {
          Bucket: "paywell-user-runs",
          studio: studioName,
          // fileName: file.name,
          fileName: fileNameWithExtension,
          payPeriod: payPeriod
        },
        queryStringParameters: {
          Bucket: "paywell-user-runs",
          studio: studioName,
          // fileName: file.name,
          fileName: fileNameWithExtension,
          payPeriod: payPeriod
        },
      };
      var s3FormData;
      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", new Blob([data], { type: file.type }));

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
      } else {
        console.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }


}
export function getSubstringBeforeFirstDot(str) {
  
  // Split the string at the first occurrence of "."
  const parts = str.split(".");
  // Return the first element which contains the substring before the first dot
  return parts[0];
}
export function removeSubstringBeforeFirstDot(str) {
  // Count the number of dots in the string
  const dotCount = str.split(".").length - 1;
  
  // If there is only one dot, return the original string unchanged
  if (dotCount === 1) {
      return str;
  }
  
  // If there are multiple dots, find the index of the first dot
  const dotIndex = str.indexOf(".");
  
  // Return everything after the first dot
  if (dotIndex !== -1) {
      return str.slice(dotIndex + 1); // +1 to exclude the dot itself
  }
  
  // If no dot is found (for completeness), return the original string
  return str;
}
export async function uploadSessionSnapshotToS3(
  file,
  studioName,
  uid,
  payPeriodStartDate,
  payPeriodEndDate,
  fileName = "", // Add a parameter for the file name
  isSessionSnapshotUpload = false
) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // Check if 'file' is a real file, Blob, or neither
    if (!(file instanceof File || file instanceof Blob)) {
      console.error("Invalid file parameter. 'file' must be a File or Blob object.");
      return;
    }

    // If 'file' is a File, convert it to a Blob and use its name
    if (file instanceof File) {
      file = new Blob([await file.arrayBuffer()], { type: file.type });
      fileName = file.name; // Use the name of the File object
    }


    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const startDate = formatDate(new Date(payPeriodStartDate));
      const endDate = formatDate(new Date(payPeriodEndDate));
      const payPeriod = startDate + "_" + endDate;

      const myAPI = "paywellAPIResource";
      const path = "/createPresignedPost";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {
          Bucket: "paywell-user-runs",
          // uid, // Admin only
          studio: studioName,
          fileName: fileName,
          payPeriod: payPeriod,
          isSessionSnapshotUpload
        },
        queryStringParameters: {
          Bucket: "paywell-user-runs",
          // uid, // Admin only
          studio: studioName,
          fileName: fileName,
          payPeriod: payPeriod,
          isSessionSnapshotUpload
        },
      };

      var s3FormData;

      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", file); // Append the Blob or File here

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
        // You can return or handle the successful upload response here.
        return;
      } else {
        console.error("File upload failed.");
        // Handle the failed upload response or throw an error.
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error appropriately or rethrow it if needed.
      throw error;
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }

}

export async function sendToS3(
  file,
  key
) {
  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const myAPI = "paywellAPIResource";
      const path = "/createS3UploadUrl";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {

          Bucket: process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          Key: key
        },
        queryStringParameters: {
          Bucket: process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          Key: key
        },
      };

      var s3FormData;

      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", file); // Append the Blob or File here

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
        // You can return or handle the successful upload response here.
        return;
      } else {
        console.error("File upload failed.");
        // Handle the failed upload response or throw an error.
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error appropriately or rethrow it if needed.
      throw error;
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }

}

export async function uploadTestOutput(latestSessionPath, outputWorkbook, outputFileName = false) {
  outputFileName = outputFileName || "test-output.xls";
  // ~~~~ need to think of way to decipher between live run and auto test run. one will upload initial session output while test will append the newly tested output.

  // GET LATEST SESSION PATH
  const currentDate = new Date().toLocaleDateString().replace(/\//g, '-');
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
  const datetime = `${currentDate}_${currentTime}`;


  // GET OUTPUT FILE
  const wbout = XLSX.write(outputWorkbook, {
    bookType: "xlsx",
    type: "array"
  });
  const excelBlob = new Blob([wbout], {
    type: "application/octet-stream"
  });
  const testOutputS3Path = `${latestSessionPath}session_testing/session_test_${datetime}/${outputFileName}`;
  await sendToS3(excelBlob, testOutputS3Path);

  return testOutputS3Path;
}


