import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useRef,
} from "react";
import {
  updateStudioSettings,
  getUserSettings,
  deleteStudioSettings,
  addStudioSettings,
} from "../services/studio.services";
import { useAuth } from "./AuthContext";
import {
  newStudioSettings,
  newStaffSettings,
} from "../pages/StudioSettings/CommonVariables.js";
import { RevertList } from "../pages/Components/RevertList";

import {
  checkStaffSettingsFormat,
  checkStudioSettingsFormat,
} from "../utility-functions/FirebaseConverter/SettingsCheck";

import { useNetworkStatus } from "./NetworkStatusContext/NetworkStatusContext";
import {
  buildReportCompilerStateErrorList,
  buildReportCompilerStateStaff,
  initialReportCompiler,
} from "./ReportCompiler/utility-functions.js";
import { useSnackBar } from "./SnackBarContext/SnackBarContext";
/**** HELPER FUNCTIONS ****/
import {
  addTimeEvent,
  adjustAgreements,
  deleteDuplicateTimeObjects,
  getUnattendedBookingEvents,
  removeTimeEvent,
  resetGlobalVariables,
} from "./ReportCompiler/utility-functions.js";

import {
  updateObject,
  findPayRates,
  getFirstNumericKey,
  getUrlVariableValue,
  addOrUpdateUrlVariable,
} from "../utility-functions/utility-functions.js";
import { buildReportCompilerState } from "./ReportCompiler/utility-functions.js";
import {
  compileTimePunchDetailData,
  addExcelData,
  handleDownload,
  buildReportCompilerStateQuestions,
} from "./ReportCompiler/utility-functions.js";
import { useHistory } from "react-router-dom";

import {
  getReportCompilerStateFromLatestSessionSnapshot
} from "../utility-functions/AWS/S3-Helper-Functions.js";
import {
  START_DOWNLOAD,
  COMPLETE_DOWNLOAD,
  ERROR_DOWNLOAD,
  middlewareEnhancer,
  asyncMiddleware
} from "./Middleware/AsyncMiddleware.js";

const SettingsContext = React.createContext();
export function useSettingsContext() {
  return useContext(SettingsContext);
}

/**
 * Sets the isActiveSettingPreset property for the specified settingsId in the settings object,
 * and updates other settings objects to set isActiveSettingPreset to false.
 *
 * @param {object} settings - The settings object containing settings configurations.
 * @param {number} settingsId - The settings ID to be set as active.
 */
function setActiveSettingsPreset(settings, activeSettingsId) {
  const updatedSettings = { ...settings };
  for (const key in updatedSettings) {
    if (!isNaN(key)) {
      if (
        key === activeSettingsId &&
        updatedSettings[key].hasOwnProperty("settingsId") &&
        updatedSettings[key].settingsId == activeSettingsId
      ) {
        updatedSettings[key].isActiveSettingPreset = true;
      } else {
        updatedSettings[key].isActiveSettingPreset = false;
      }
    }
  }

  return updatedSettings;
}

/**
 * Ensures that the provided settingsId is valid in the settings object.
 * If the provided settingsId is does not exist, missing, null, or invalid, a default valid settingsId is set.
 *
 * @param {object} settings - The settings object containing settings configurations.
 * @param {number} settingsId - The settings ID to be checked and ensured as valid.
 */
function ensureValidSettingsId(settings, settingsId) {
  if (
    !settingsId ||
    isNaN(settingsId) ||
    !settings.hasOwnProperty(settingsId)
  ) {
    const defaultSelectedSettingsId = getFirstNumericKey(settings);
    addOrUpdateUrlVariable("settingsId", defaultSelectedSettingsId);
  }
}

const settingsReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    // dispatch({
    //   type: "SET_SETTINGS_PRESET",
    //   settingsId: 0,
    //   uid: "12345678",
    //   presetSettings: {},
    // });
    case "SET_SETTINGS_PRESET":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId] = action.presetSettings;
        return newState;
      } else {
        console.error(
          "SET_SETTINGS_PRESET: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_SETTINGS_PROPERTY",
    //   settingsId: 0,
    //   uid: "12345678",
    //   path: "",
    //   value: "",
    //
    // });
    case "UPDATE_SETTINGS_PROPERTY":
      //
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        if (action.replace) {
          updateObject(newState, action.path, action.value, action.replace);
        } else {
          updateObject(newState, action.path, action.value);
        }

        return newState;
      } else {
        console.error(
          "UPDATE_STUDIO_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_ACTIVE_STUDIO_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   isActiveSettingPreset: false,
    // });
    case "UPDATE_ACTIVE_STUDIO_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        for (let key in newState) {
          newState[key].isActiveSettingPreset = false;
        }

        newState[action.settingsId].isActiveSettingPreset =
          action.isActiveSettingPreset;

        return newState;
      } else {
        console.error(
          "UPDATE_ACTIVE_STUDIO_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_STUDIO_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   studios: ["Studio 1, Studio 2, Studio 3"],
    // });
    case "UPDATE_STUDIO_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].generalSettings.studios = action.studios;
        return newState;
      } else {
        console.error(
          "UPDATE_STUDIO_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_CLASS_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   classSettings: {},
    // });
    case "UPDATE_CLASS_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].classSettings = action.classSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_STUDIO_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_CLASS_SETTINGS_RATE",
    //   settingsId: 0,
    //   uid: "12345678",
    //   classType: "",
    //   rateId: "",
    //   newRate: "",
    // });
    case "UPDATE_CLASS_SETTINGS_RATE":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].classSettings.classBuckets[
          action.classType
        ].rate[action.rateId] = action.newRate;
        return newState;
      } else {
        console.error(
          "UPDATE_STUDIO_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
    //   settingsId: 0,
    //   uid: "12345678",
    //   classType: "",
    //   key:
    //   value: "",
    // });
    case "UPDATE_CLASS_SETTINGS_WITH_KEY":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].classSettings.classBuckets[
          action.classType
        ][action.key] = action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_CLASS_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_GENERAL_CLASS_SETTINGS_WITH_KEY",
    //   settingsId: 0,
    //   uid: "12345678",
    //   key:
    //   value: "",
    // });
    case "UPDATE_GENERAL_CLASS_SETTINGS_WITH_KEY":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].classSettings.general[action.key] =
          action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_GENERAL_CLASS_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }
    // dispatch({
    //   type: "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY",
    //   settingsId: 0,
    //   uid: "12345678",
    //   key: ""
    //   value: "",
    // });
    case "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].classSettings.general.fullClassSettings[
          action.key
        ] = action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_TIME_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   timeSettings: {},
    // });
    case "UPDATE_TIME_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].timeSettings = action.timeSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_TIME_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }
    // dispatch({
    //   type: "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY",
    //   settingsId: 0,
    //   uid: "12345678",
    //   key: "",
    //   value: "",
    // });
    case "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].timeSettings.general[action.key] =
          action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_COMMISSION_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   commissionSettings: {},
    // });
    case "UPDATE_COMMISSION_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].commissionSettings =
          action.commissionSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_COMMISSION_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_FORMAT_SETTINGS",
    //   settingsId: 0,
    //   uid: "12345678",
    //   formatSettings: {}
    // });
    case "UPDATE_FORMAT_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].formatSettings = action.formatSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_FORMAT_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    case "UPDATE_GENERAL_SETTINGS":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].generalSettings = action.generalSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_GENERAL_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    case "UPDATE_SALES_SETTINGS_WITH_KEY":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].salesSettings.salesBuckets[
          action.salesType
        ][action.key] = action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_CLASS_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_LAST_EDIT_DATE",
    //   settingsId: 0,
    //   uid: "12345678",
    //   lastEdit: "12/29/2022",
    // });

    case "UPDATE_SALES_SETTINGS_ON":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].salesSettings[action.key] = action.value;
        return newState;
      } else {
        console.error(
          "UPDATE_CLASS_SETTINGS_WITH_KEY: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }
    case "UPDATE_LAST_EDIT_DATE":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].generalSettings = action.generalSettings;
        return newState;
      } else {
        console.error(
          "UPDATE_GENERAL_SETTINGS: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_SETTINGS_TITLE"",
    //   settingsId: 0,
    //   uid: "12345678",
    //   settingsTitle: "Massachusetts",
    // });
    case "UPDATE_SETTINGS_TITLE":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].settingsTitle = action.settingsTitle;
        return newState;
      } else {
        console.error(
          "UPDATE_SETTINGS_TITLE: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_SETTINGS_ID",
    //   settingsId: 0,
    //   uid: "12345678",
    //   newSettingsId: 12,
    // });
    case "UPDATE_SETTINGS_ID":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].settingsId = action.newSettingsId;
        return newState;
      } else {
        console.error(
          "UPDATE_SETTINGS_ID: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_SETTINGS_UID",
    //   settingsId: 0,
    //   uid: "12345678",
    //   newUid: "vBOauO35j9OZ9AFxRqxaZCm6dP62",
    // });
    case "UPDATE_SETTINGS_UID":
      if (
        newState[action.settingsId]?.uid == action.uid &&
        newState[action.settingsId]?.settingsId == action.settingsId
      ) {
        newState[action.settingsId].uid = action.newUid;
        return newState;
      } else {
        console.error(
          "UPDATE_SETTINGS_UID: The settings id you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_STAFF_SETTINGS_LAST_EDIT_DATE
    //   uid: "12345678",
    //   lastEdit: "12/22/18"
    // });
    case "UPDATE_STAFF_SETTINGS_LAST_EDIT_DATE":
      if (newState.staff.uid == action.uid) {
        newState.staff.lastEdit = action.lastEdit;
        return newState;
      } else {
        console.error(
          "UPDATE_STAFF_SETTINGS_LAST_EDIT_DATE: The staffSettings uid or you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_STAFF_SETTINGS_UID
    //   uid: "12345678",
    //   newUid: "12342134"
    // });
    case "UPDATE_STAFF_SETTINGS_UID":
      if (newState.staff.uid == action.uid) {
        newState.staff.uid = action.newUid;
        return newState;
      } else {
        console.error(
          "UPDATE_STAFF_SETTINGS_UID: The staffSettings uid or you are trying to update does not match the current users uid."
        );
        return state;
      }

    // dispatch({
    //   type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER
    //   uid: "12345678",
    //   id: "719eade0-3973-41d9-99b9-f79d797e6f77",
    //   newStaff: {},
    // });
    case "UPDATE_STAFF_SETTINGS_STAFF_MEMBER":
      let oldStaff = newState.staff.staff[action.id];

      if (
        newState.staff.uid == action.uid &&
        oldStaff?.paywellUID == action.uid
      ) {
        newState.staff.staff[action.id] = action.newStaff;
        return newState;
      } else {
        console.error(
          "UPDATE_STAFF_SETTINGS_STAFF_MEMBER: The staff member uid, name, idString, or id you updating does nnot line up"
        );
        return state;
      }



    // dispatch({
    //   type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER_STATUS
    //   uid: "12345678",
    //   id: "719eade0-3973-41d9-99b9-f79d797e6f77",
    //   status: delete, update, new, default
    // });
    case "UPDATE_STAFF_SETTINGS_STAFF_MEMBER_STATUS":
      let beforePreDeleteStaff = newState.staff.staff[action.id];

      if (
        newState.staff.uid == action.uid &&
        beforePreDeleteStaff?.paywellUID == action.uid
      ) {
        newState.staff.staff[action.id]["status"] = action.status;
        newState.staff.staff[action.id].updated = true;
        return newState;
      } else {
        console.error(
          "UPDATE_STAFF_SETTINGS_STAFF_MEMBER_STATUS: The staff member uid, name, idString, or id you updating does nnot line up"
        );
        return state;
      }

    // dispatch({
    //   type: "RESET_ALL_STAFF_SETTINGS_STAFF_MEMBER_STATUSES"
    //   uid: "12345678"
    // });
    case "RESET_ALL_STAFF_SETTINGS_STAFF_MEMBER_STATUSES":
      const paywellUID = action.uid;

      Object.values(newState.staff.staff).forEach((obj) => {
        if (obj.paywellUID === paywellUID) {
          obj.status = "";
        }
      });

      return state;

    // dispatch({
    //   type: "DELETE_STAFF_SETTINGS_STAFF_MEMBER
    //   uid: "12345678",
    //   id: "719eade0-3973-41d9-99b9-f79d797e6f77",

    // });
    case "DELETE_STAFF_SETTINGS_STAFF_MEMBER":
      let staffToDelete = newState.staff.staff[action.id];
      if (
        newState.staff.uid == action.uid &&
        staffToDelete?.paywellUID == action.uid
      ) {
        // Convert `staff object` to a key/value array
        const staffAsArray = Object.entries(newState.staff.staff);
        const filteredStaffArray = staffAsArray.filter(
          ([key, value]) => !(key === action.id)
        );
        // Convert the key/value array back to an object:
        const filteredStaffObject = Object.fromEntries(filteredStaffArray);
        newState.staff.staff = filteredStaffObject;

        return newState;
      } else {
        console.error(
          "DELETE_STAFF_SETTINGS_STAFF_MEMBER: The staff member uid, name, idString, or id you updating does nnot line up"
        );
        return state;
      }

    // dispatch({
    //   type: "ADD_STAFF_SETTINGS_STAFF_MEMBER
    //   newStaffId: "719eade0-3973-41d9-99b9-f79d797e6f77"
    //   newStaff: {},
    // });
    case "ADD_STAFF_SETTINGS_STAFF_MEMBER":
      newState.staff.staff[action.newStaffId] = action.newStaff;
      return newState;

    // dispatch({
    //   type: "SET_ALL_STAFF
    //   allStaff: {}
    // });
    case "SET_ALL_STAFF":
      newState = action.allStaff;
      return newState;

    // dispatch({
    //   type: "SET_ALL_SETTINGS",
    //   allSettings: {},
    // });
    case "SET_ALL_SETTINGS":
      newState = action.allSettings;

      if (newState) {
        let selectedSettingsId = false;

        for (let key in newState) {
          if (newState[key].isActiveSettingPreset) {
            selectedSettingsId = newState[key].settingsId;
          }
        }

        if (!selectedSettingsId) {
          selectedSettingsId = getFirstNumericKey(newState);
          newState[selectedSettingsId].isActiveSettingPreset = true;
        }
      }

      return newState;

    default:
      return state;
  }
};


const useMiddlewareReducer = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchWithMiddleware = (action) => {
    if (typeof action === "function") {
      return action(dispatch);
    } else {
      return dispatch(action);
    }
  };

  return [state, dispatchWithMiddleware];
};


function findDuplicates(arr, excludeProps = []) {
  const duplicates = [];
  const uniqueObjects = new Map(); // Change to Map to store both objects and their original index

  arr.forEach((obj, index) => {
      // Create a copy of the object excluding specified properties
      const filteredObj = Object.keys(obj)
          .filter(key => !excludeProps.includes(key))
          .reduce((newObj, key) => {
              newObj[key] = obj[key];
              return newObj;
          }, {});

      const stringifiedObj = JSON.stringify(filteredObj);

      if (uniqueObjects.has(stringifiedObj)) {
          duplicates.push({
              original: uniqueObjects.get(stringifiedObj),
              duplicate: { index, obj }
          });
      } else {
          uniqueObjects.set(stringifiedObj, { index, obj });
      }
  });

  // Log all the duplicates side by side
  // duplicates.forEach(dup => {
  //     console.log(`Original (index ${dup.original.index}):`, dup.original.obj);
  //     console.log(`Duplicate (index ${dup.duplicate.index}):`, dup.duplicate.obj);
  //     console.log('---');
  // });

  console.log("All duplicates: ", duplicates);

  const totalDuplicates = duplicates.length;

  console.log(`Total duplicates found: ${totalDuplicates} out of ${arr.length}`);

  return totalDuplicates;
}

export default function SettingsContextProvider({ children }) {
  const history = useHistory();
  const showSnackBar = useSnackBar();
  const offline = useNetworkStatus();
  const [settings, dispatch] = useReducer(settingsReducer, false);
  const { getUID } = useAuth();
  const [useInputFile, setUseInputFile] = useState(false);
  const [selectedSettingsId, setSelectedSettingsId] = useState(
    getFirstNumericKey(settings)
  );
  const [activeSettings, setActiveSettings] = useState(false);
  const [studioSettings, setStudioSettings] = useState({});
  const [staffSettings, setStaffSettings] = useState({});
  const [changes, setChanges] = useState(new RevertList([]));
  const changeValues = {
    changes,
    setChanges,
  };
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [settingsMenuButtonsDisabled, setSettingsMenuButtonsDisabled] =
    useState(true);

  const dataFetchedRef = useRef(false);
  const handleDownloadAsync = (action, settings) => {
    reportCompilerDispatch({
      type: "START_DOWNLOAD"
    })

    return async (reportCompilerDispatch) => {
      const newState = await handleDownload(action, reportCompilerState, settings);
      reportCompilerDispatch({ type: "UPDATE_STATE_AFTER_DOWNLOAD", newState });
    };
  };

  const reportCompilerReducer = (state, action) => {
    console.log("reportCompilerReducer action: ", action)
    console.log("reportCompilerReducer state: ", state)

    let newState = { ...state };

    switch (action?.type) {
      case "START_DOWNLOAD":
        newState.output.loading = true;
        return newState;
      case "UPDATE_STATE_AFTER_DOWNLOAD":
        console.log("new state after handle download: ", action.newState)
        action.newState.output.loading = false;

        // duplicate check for debi error
        // findDuplicates(action.newState.inputFilesArrays.BEL, ["className", "valid"]);

        return action.newState;

      case "HANDLE_DOWNLOAD":

        newState = handleDownload(action, newState, settings);

        return newState;


      case "COMPILE_TIME_PUNCH_DETAIL_DATA":
        newState = compileTimePunchDetailData(action, newState);
        return newState;

      // ~~~~ this is awful and needs to make more sense. nothing is logical about how this is organized.

      case "MERGE_ALL_STUDIO_REPORT_DATA":
        let reportTypes = newState.scrapeInfo.reportsNeeded;
        for (let i = 0; i < reportTypes.length; i++) {
          for (let reportArray in newState.inputFilesArrays) {
            if (
              reportArray.includes(reportTypes[i]) &&
              !reportArray.includes("Uploaded")
            ) {
              if (reportTypes[i].toUpperCase() === "GROSS") {
                newState.inputFilesArrays["SALE"] = newState.inputFilesArrays[
                  "SALE"
                ].concat(newState.inputFilesArrays[reportArray]);
              } else {
                newState.inputFilesArrays[reportTypes[i].toUpperCase()] =
                  newState.inputFilesArrays[
                    reportTypes[i].toUpperCase()
                  ].concat(newState.inputFilesArrays[reportArray]);
              }
            } else if (
              reportArray.includes("time") &&
              reportTypes[i] == "time" &&
              !reportArray.includes("Uploaded")
            ) {
              newState.inputFilesArrays["TIME"] = newState.inputFilesArrays[
                "TIME"
              ].concat(newState.inputFilesArrays[reportArray]);
            }
          }

          if (reportTypes[i] === "time") {
            newState.inputFilesArrays.TIME =
              deleteDuplicateTimeObjects(newState);
          }
        }

        // Update PayWellOutputProgress
        newState.PayWellOutputProgress = "1/9";
        return newState;

      case "SET_SELECTED_DATES":
        newState.scrapeInfo.selectedDates = [
          action.selectedDates[0],
          action.selectedDates[1],
        ];
        return newState;


      case "FIND_PAY_RATES":
        newState.inputFilesArrays.TIME = findPayRates(
          newState.inputFilesArrays,
          newState.studiosInformation
        );

        // Update PayWellOutputProgress
        newState.PayWellOutputProgress = "2/9";
        return newState;


      case "ADD_TIME_CLOCK_CATCH_ALL":
        let timeLogic = newState.inputFilesArrays.questions.timeCatchAllObject;
        for (let n = 0; n < timeLogic.length; n++) {
          if (timeLogic[n].utility === "Add") {
            newState.inputFilesArrays.TIME =
              newState.inputFilesArrays.TIME.concat(
                addTimeEvent(timeLogic[n], newState)
              );
          } else if (timeLogic[n].utility === "Remove") {
            newState.inputFilesArrays.TIME = removeTimeEvent(
              timeLogic[n],
              newState
            );
          }
        }

        // Update PayWellOutputProgress
        newState.PayWellOutputProgress = "3/9";
        return newState;


      case "ADD_UNATTENDED_BOOKING_EVENTS":
        let unattendedBookingEvents = getUnattendedBookingEvents(newState);
        if (unattendedBookingEvents) {
          newState.inputFilesArrays.BEL = newState.inputFilesArrays.BEL.concat(
            unattendedBookingEvents
          );

          //newState.inputFilesArrays.BEL.push(unattendedBookingEvents);
        }

        // Update PayWellOutputProgress
        newState.PayWellOutputProgress = "4/9";
        return newState;
      //  reportCompilerDispatch({
      //   type: "ADJUST_AGREEMENTS",
      // });
      case "ADJUST_AGREEMENTS":
        const unadded = adjustAgreements(newState);

        // if (unadded) {
        //   // ~~~~ What do we do with undadded?
        // }

        // Update PayWellOutputProgress
        newState.PayWellOutputProgress = "5/9";
        return newState;
      // serializeObjectWithMethods(newState);

      /*
      reportCompilerDispatch({
        type: "ADD_EXCEL_DATA",
        fileId:
        workbook:
       });
      */
      case "ADD_EXCEL_DATA":
        // THIS DOES NOT ACCOUNT FOR TIME PUNCH DETAIL DATA ~~~~ SCOTT SMITH
        // IF YOU EDIT THIS MAKE SURE YOU EDIT THE TEST FUNCTIOJN AS WELL
        newState = addExcelData(action, newState);
        return newState;

      //  reportCompilerDispatch({
      //   type: "UPDATE_REPORT_COMPILER_SCRAPE_STATUS",
      //   value: name,
      // });
      case "UPDATE_REPORT_COMPILER_SCRAPE_STATUS":
        newState.scrapeInfo.isScrapeActive = action.value;

        return newState;

      //  reportCompilerDispatch({
      //   type: "UPDATE_REPORT_COMPILER_PROPERTY",
      //   path: `formatSettings.processorMapping.columns.name`,
      //   value: name,
      //   replace: true
      // });
      case "UPDATE_REPORT_COMPILER_PROPERTY":
        updateObject(
          newState,
          action.path,
          action.value,
          action.hasOwnProperty("replace") ? action.replace : true
        );

        return newState;

      // reportCompilerDispatch({
      //   type: "REMOVE_REPORT_COMPILER_REPORT",
      //   reportPropertyName: "bel1",
      // });
      case "REMOVE_REPORT_COMPILER_REPORT":
        newState.inputFilesArrays[action.reportPropertyName] = [];

        return newState;

      case "BUILD_REPORT_COMPILER_STATE":
        if (action.settings) {
          newState = buildReportCompilerState(action.settings);
        } else {
          newState = buildReportCompilerState(settings);
        }
        return newState;

      case "BUILD_REPORT_COMPILER_STATE_STAFF":

        newState = buildReportCompilerStateStaff(
          settings,
          settings[getUrlVariableValue("settingsId")],
          newState
        );

        return newState;

      case "BUILD_REPORT_COMPILER_STATE_ERROR_LIST":

        newState = buildReportCompilerStateErrorList(newState);

        return newState;

      case "BUILD_REPORT_COMPILER_STATE_QUESTIONS":

        const selectedSettingsPreset =
          settings[getUrlVariableValue("settingsId")];

        // Use the helper function to build questions
        const updatedState = buildReportCompilerStateQuestions(
          settings,
          selectedSettingsPreset,
          newState
        );

        // Return the new state object produced by the function
        return updatedState;

      case "RESET_REPORT_COMPILER_STATE":
        newState = resetGlobalVariables();
        return newState;

      case "ADD_LATEST_SESSION_SNAPSHOT_DATA":
        // might need to use updateObject() becuase of deepClone issues in past
        newState = getReportCompilerStateFromLatestSessionSnapshot(uid, selectedSettingsId, newState);
        return newState;

      //  reportCompilerDispatch({
      //   type: "ADD_CLASSES_IN_FILE_TO_REPORT_COMPILER",
      //   path: `formatSettings.processorMapping.columns.name`,
      //   value: name,
      // });
      case "ADD_CLASSES_IN_FILE_TO_REPORT_COMPILER":
        // updateObject(newState, action.path, action.value);

        newState.payrollInformation.classesInFiles.push(action.value);

        return newState;

      //  reportCompilerDispatch({
      //   type: "CREATE_INSTRUCTOR_ARRAY",
      // });
      case "CREATE_INSTRUCTOR_ARRAY":
        let instructorArray = [];

        instructorArray = action.staff.filter((s) => s.type === "Instructor");

        if (newState.inputFilesArrays.questions.organizeStaffLastName.value) {
          instructorArray.sort(sortArrayLastName);
        }

        newState.studiosInformation.instructorsArray = instructorArray;
        return newState;

      //  reportCompilerDispatch({
      //   type: "CREATE_INSTRUCTOR_ARRAY",
      // });
      case "CREATE_STAFF_ARRAYS":
        // needs the full staff list (input1pay) and the locations we are currently running for
        let fullStaffList = action.staff;
        let locations = action.locations;

        let staff = [];
        let instructors = [];

        for (let i = 0; i < fullStaffList.length; i++) {
          for (let j = 0; j < locations.length; j++) {
            if (fullStaffList[i].locations.includes(locations[j])) {
              usedStaff.push(fullStaffList[i]);
              if (fullStaffList[i].type === "Instructor") {
                instructors.push(fullStaffList[i]);
              }
              break;
            }
          }
        }

        newState.studiosInformation.staffArray = staff;
        newState.studiosInformation.instructorsArray = instructors;
        if (newState.inputFilesArrays.questions.organizeStaffLastName.value) {
          newState.studiosInformation.staffArray.sort(sortArrayLastName);
          newState.studiosInformation.instructorsArray.sort(sortArrayLastName);
        }

        return newState;
      default:
        return state;
    }
  };
  // const [reportCompilerState, reportCompilerDispatch] = useReducer(
  //   reportCompilerReducer,
  //   initialReportCompiler
  // );
  const [reportCompilerState, reportCompilerDispatch] = useMiddlewareReducer(
    reportCompilerReducer,
    initialReportCompiler
  );

  // const [reportCompilerState, reportCompilerDispatch] = useReducer(
  //   middlewareEnhancer([asyncMiddleware], reportCompilerReducer),
  //   initialReportCompiler
  // );

  // Needs to be fixed ~~~~ check what this fucniton look slike on main
  const initializeNewSettings = async (settingsId) => {
    var defaultSettingsTitle = "PayWell Settings #" + settingsId;

    let uid = getUID();
    let res;
    let studioSettings;
    let staffSettings;

    try {
      studioSettings = await createNewStudioSettings(
        newStudioSettings,
        uid,
        settingsId
      );
      staffSettings = await createNewStaffSettings(
        newStaffSettings,
        uid,
        settingsId
      );

      // new dispatch
      // setStaffSettings(staffSettings);

      // setStudioSettings(studioSettings);
      var allSettings = { staff: staffSettings };
      allSettings[settingsId] = studioSettings;
      allSettings[settingsId].settingsTitle = defaultSettingsTitle;
      allSettings[settingsId].isActiveSettingPreset = true;

      // ~~~~ do I need this scott?
      // ~~~~ what is this atually doing?
      // await updatePayWellSettings(uid, response.settings);
      dispatch({
        type: "SET_ALL_SETTINGS",
        allSettings: allSettings,
      });

      // dispatchStepper({ type: "ENABLE_NEXT_BUTTON" });

      // check that the new settings are correctly pulled from firebase,
      // if so navigate to settings page
      // await initializeSettings();

      return {
        studioSettings,
        staffSettings,
        settingsId,
      };
    } catch (e) {
      console.error(
        "Failed creating new settings preset for uid: ",
        uid,
        " Error: ",
        e
      );
      return false;
    }
  };
  async function updatePayWellSettings(uid, settings) {
    let updatedStudioSettings = checkStudioSettingsFormat(settings, uid);
    let updatedStaffSettings = checkStaffSettingsFormat(settings.staff, uid, updatedStudioSettings);

    return [
      updatedStaffSettings,
      updatedStudioSettings[getUrlVariableValue("settingsId")],
    ];
  }

  async function initSettingsPresets(retryCount = 0) {
    if (!offline) {
      // Fetch Updated Firebase data, then set actively selected settings
      let uid = getUID();

      getUserSettings(uid).then(async (response) => {
        if (response && response.error) {
          alert(response.error);
        } else if (response && response.settings) {
          let settingsId = getUrlVariableValue("settingsId");

          ensureValidSettingsId(response.settings, settingsId);

          response.settings = setActiveSettingsPreset(
            response.settings,
            getUrlVariableValue("settingsId")
          );

          await updatePayWellSettings(uid, response.settings);

          /**** SETS ARRAY THAT COMBINES ALL STUDIOS FROM ALL PRESETS ****/
          const studiosFromAllPresets = getStudiosFromAllPresets(
            response.settings
          );
          response.settings.staff["studiosFromAllPresets"] =
            studiosFromAllPresets;

          /**** ENABLE SETTINGS NAV DRAWER ****/
          setSettingsMenuButtonsDisabled(false);

          dispatch({
            type: "SET_ALL_SETTINGS",
            allSettings: response.settings,
          });
        } else {
          if (retryCount < 5) {
            await initSettingsPresets(retryCount + 1);
            // location.reload();
          } else {
            // Handle when the maximum number of retries has been reached
            console.error("Max retries reached!");

            showSnackBar(
              `There was a problem with your connection. Please login again.`,
              "warning"
            );
            setTimeout(() => history.push("/login"), 1000);

            // showSnackBar(
            //   `Please schedule a meeting to get started using PayWell!`,
            //   "warning"
            // );
          }
        }
      });
    }
  }

  function getStudiosFromAllPresets(settings) {
    let concatenatedStudios = [];

    // Iterate through each key-value pair in the object
    for (const key in settings) {
      // Check if the key is a number
      if (!isNaN(Number(key))) {
        if (settings[key].generalSettings?.studios) {
          // Concatenate the studios into the array
          concatenatedStudios = concatenatedStudios.concat(
            settings[key].generalSettings.studios
          );
        }
      }
    }

    return concatenatedStudios;
  }

  const createNewStudioSettings = async (
    newStudioSettings,
    uid,
    settingsId
  ) => {
    const newSettings = {
      ...newStudioSettings,
      ["uid"]: uid,
      ["settingsId"]: settingsId,
    };

    // ~~~~~ THIS IS HOW SETTINGS WERE BEINNG RESET SCOTT
    // await updateStudioSettings(newSettings, uid, settingsId);
    await addStudioSettings(newSettings, uid, settingsId);
    return newSettings;
  };
  const createNewStaffSettings = async (newStaffSettings, uid, settingsId) => {
    let newSettings = {
      ...newStaffSettings,
      ["uid"]: uid,
      ["settingsId"]: settingsId,
    };
    return newSettings;
  };
  // const getSettings = async (uid, settingsId) => {
  //   let staffSettings;
  //   if (studioSettings !== null) {
  //     staffSettings = await getStaffSettings(uid, settingsId);
  //     return {
  //       studioSettings: studioSettings,
  //       staffSettings: staffSettings,s
  //       settingsId: settingsId,
  //     };
  //   }
  //   return false;

  //   // Do this once user clicks setup settings card button
  //   // return initializeNewSettings(newStudioSettings, newStaffSettings, uid);
  // };
  async function loadAdminSettings(selectedUserUID) {
    let pulledSettings = await getUserSettings(selectedUserUID);
    if (pulledSettings) {
      dispatch({ type: "SET_SETTINGS", newSettings: pulledSettings });
      return pulledSettings;
    } else {
      dispatch({ type: "SET_SETTINGS", newSettings: false });
      return false;
    }
  }
  // Returns users existing settings else returns false

  async function updateUserSettings(
    newestStaffSettings,
    newestStudioSettings,
    settingsId,
    adminUID = false
  ) {
    let uid = getUID();

    if (newestStaffSettings !== null) {
      setStaffSettings(newestStaffSettings);
    }
    if (newestStudioSettings !== null) {
      setStudioSettings(newestStudioSettings);
      await updateStudioSettings(newestStudioSettings, uid, settingsId);
    }
  }

  async function handleDeleteSettings(settingsId) {
    let uid = getUID();
    try {
      await handleDeleteStaffSettings(uid, settingsId);
      await handleDeleteStudioSettings(uid, settingsId);
    } catch (e) {
      console.error(
        "Error deleting settings for UID: ",
        uid,
        " SETTINGSID: ",
        settingsId,
        " : ",
        e
      );
      return false;
    }

    return true;
  }
  async function handleDeleteStudioSettings(uid, settingsId) {
    let res;
    res = await deleteStudioSettings(uid, settingsId);

    return res;
  }
  async function handleDeleteStaffSettings(uid, settingsId) {
    let res;
    //res = await deleteStaffSettings(uid, settingsId);
    return res;
  }




  useEffect(() => {
    if (changes.getLength() > 0) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [changes]);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    initSettingsPresets();


  }, []);

  // Context
  const value = {
    handleDownloadAsync,
    settings,
    dispatch,
    reportCompilerState,
    reportCompilerDispatch,
    initSettingsPresets,
    settingsMenuButtonsDisabled,
    setSettingsMenuButtonsDisabled,
    selectedSettingsId,
    setSelectedSettingsId,
    studioSettings,
    setStudioSettings,
    staffSettings,
    setStaffSettings,
    updateUserSettings,
    changes,
    setChanges,
    changeValues,
    saveDisabled,
    setSaveDisabled,
    activeSettings,
    setActiveSettings,
    initializeNewSettings,
    handleDeleteSettings,
    loadAdminSettings,
    useInputFile,
    setUseInputFile,
  };

  // async function confirmUserHassettings(){}

  // if not loading then we render children of auth provider
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
