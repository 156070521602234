import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";

export function readClubReadyTimeClockSummary(json, studiosInformation) {
  const timeArray = [];
  let staffInFile = [];

  let nameI = 0;
  let location = "";
  let totalMinI = 0;
  //let payRateI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isTimeHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Employee Name")) {
          nameI = u;
        } else if (
          json[i][u].includes("Total") &&
          json[i][u].includes("Minutes")
        ) {
          totalMinI = u;
        }
        // else if (json[i][u].includes("Pay Rate")) {
        //   payRateI = u;
        // }
      }
      correctFileType = true;
    } else if (isTimeDataRow(json[i])) {
      let hours = findHours(json[i][totalMinI]);

      let pay = findHourlyPayRate(json[i][nameI], location, studiosInformation);

      if (hours !== 0) {
        const newStaff = {
          name: json[i][nameI],
          type: "time",
          location: location,
        };

        let isDuplicate = staffInFile.some(staff =>
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );

        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }

        // staffInFile.push({
        //   name: json[i][nameI],
        //   type: "time",
        //   location: location,
        // });
      }

      const timeEvent = new TimeEvent(
        json[i][nameI],
        location,
        hours,
        pay,
        "Regular",
        0
      );
      timeArray.push(timeEvent);
    } else if (isTimeLocationRow(json[i])) {
      const startIndex = json[i][0].indexOf("-") + 2;
      const endIndex = json[i][0].lastIndexOf("(") - 1;
      location = json[i][0].substring(startIndex, endIndex);
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isTimeHeaderRow(row) {
  return (
    row[0].includes("User ID") ||
    row[1].includes("Employee Name") ||
    row[2].includes("Home Location")
  );
}

function isTimeDataRow(row) {
  return !isNaN(parseFloat(row[0])) && row[0] !== "";
}

function findHours(minutesString) {
  let hours =
    Math.round(
      (parseFloat(minutesString.replace(",", "").replace("$", "")) / 60) * 100
    ) / 100;
  if (isNaN(parseFloat(hours))) {
    return 0;
  }
  return hours;
}

function isTimeLocationRow(row) {
  return row[0].includes("Time Clock") && row[0].includes("-");
}


export function readClubReadyTimeClockDetail(json, studiosInformation, detail) {
  const timeArray = [];
  let staffInFile = [];

  let nameI = 0;
  let dateI = 0;
  let timeI = 0;
  let hoursI = 0;
  let eventI = 0;
  let typeI = 0;
  let notesI = 0;
  let location = "";
  let locationI = 0;
  let totalMinI = 0;
  //let payRateI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isTimeDetailHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Employee Name")) {
          nameI = u;
        } else if (
          json[i][u].includes("Date")
        ) {
          dateI = u;
        } else if (
          json[i][u].includes("Local Time")
        ) {
          timeI = u;
        } else if (
          json[i][u].includes("Location Name")
        ) {
          locationI = json[i][u];
        }
        else if (
          json[i][u].includes("Hours")
        ) {
          hoursI = u;
        }
        else if (
          json[i][u].includes("Event")
        ) {
          eventI = u;
        }
        else if (
          json[i][u].includes("Type")
        ) {
          typeI = u;
        }
        else if (
          json[i][u].includes("Notes")
        ) {
          notesI = u;
        }
      }
      correctFileType = true;
    } else if (isTimeDetailDataRow(json[i])) {
      let hours = parseFloat(json[i][hoursI]);

      let rate = findHourlyPayRate(json[i][nameI], location, studiosInformation);

      let date = new Date(json[i][dateI]);

      let options = {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let formattedDate = date.toLocaleString("en-US", options);

      if (hours !== 0) {
        const newStaff = {
          name: json[i][nameI],
          type: "time",
          location: location,
        };

        let isDuplicate = staffInFile.some(staff =>
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );

        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }
      }

      // const timeEvent = new TimeEvent(
      //   json[i][nameI],
      //   location,
      //   hours,
      //   pay,
      //   "Regular",
      //   0
      // );
      if (json[i][eventI] === "CLOCK IN") {
        let timeEvent = new TimeEvent(
          json[i][nameI],
          location,
          hours,
          rate,
          formattedDate + " (" + json[i][timeI] + "-" + "???" + ")",
          hours * rate
        );

        timeEvent.date = formattedDate;
        timeEvent.punchIn = json[i][timeI];

        if (
          !detail
        ) {
          timeEvent.detail = true;
        }
        timeEvent.comment = "NO PUNCH-OUT FOUND";
        //timeEvent.punchOut = entries[x].timeOut;

        if ((i + 1) < json.length && json[i + 1][nameI] === json[i][nameI] && json[i + 1][dateI] === json[i][dateI] && json[i + 1][eventI] === "CLOCK OUT") {
          timeEvent.description = formattedDate + " (" + json[i][timeI] + "-" + json[i + 1][timeI] + ")";
          timeEvent.punchOut = json[i + 1][timeI];
          timeEvent.comment = json[i + 1][notesI];
        }

        timeArray.push(timeEvent);
      }

      // else if(json[i][eventI] === "CLOCK OUT"){
      //   for(let t = 0; t < timeArray.length; t++){
      //     if(timeArray[t].staffName === json[i][nameI] &&
      //       timeArray[t].location === location &&
      //       timeArray[t].date === formattedDate){
      //         timeArray[t].description = formattedDate + " (" + timeArray[t].punchIn + "-" + json[i][timeI] + ")";
      //         timeArray[t].punchOut = json[i][timeI];
      //         timeArray[t].comment = json[i][notesI];
      //     }
      //   }
      // }
    } else if (isTimeLocationRow(json[i])) {
      const startIndex = json[i][0].indexOf("-") + 2;
      const endIndex = json[i][0].lastIndexOf("(") - 1;
      location = json[i][0].substring(startIndex, endIndex);
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isTimeDetailHeaderRow(row) {
  return (
    row[0].includes("Employee Name") ||
    row[1].includes("Date") ||
    row[2].includes("Local Time")
  );
}

function isTimeDetailDataRow(row) {
  return (!isNaN(parseFloat(row[6])) || row[6] === '-') && row[0] !== "";
}