import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { Typography, List, ListItem, ListItemText, Divider, Button, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { getFirstSentence } from "./admin-utility-functions";

export default function NotificationList({ selectedNotification, onSelectNotification }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const db = getFirestore();
      const notificationsCollectionRef = collection(db, "notifications");

      try {
        const querySnapshot = await getDocs(notificationsCollectionRef);
        const notificationsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotifications(notificationsList);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleToggleActive = async (notification) => {
    const db = getFirestore();
    const notificationRef = doc(db, "notifications", notification.id);
    try {
      await updateDoc(notificationRef, { isActive: !notification.isActive });
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, isActive: !n.isActive } : n
        )
      );
    } catch (error) {
      console.error("Error updating notification: ", error);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    const db = getFirestore();
    const notificationRef = doc(db, "notifications", notificationId);
    try {
      await deleteDoc(notificationRef);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  const handleOrderChange = async (notification, newOrder) => {
    const db = getFirestore();
    const notificationRef = doc(db, "notifications", notification.id);
    //debugger;
    try {
      await updateDoc(notificationRef, { order: newOrder });
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, order: newOrder } : n
        )
      );
    } catch (error) {
      console.error("Error updating notification order: ", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Existing Notifications
      </Typography>
      <List>
        {notifications
          .sort((a, b) => a.order - b.order)
          .map((notification) => (
            <div   key={notification.id}>
              <ListItem alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notification.isActive}
                      onChange={() => handleToggleActive(notification)}
                      color="primary"
                    />
                  }
                />
                <TextField
                  label="Order"
                  name={"order" + notification.order}
                  type="number"
                  value={notification.order || 0}
                  onChange={(e) => handleOrderChange(notification, Number(e.target.value))}
                  style={{ width: '60px', marginRight: '16px', minWidth: "60px" }}
                />
                <ListItemText
                  primary={notification.title}
                  secondary={
                    <>

                      <Typography component="span" variant="body2" color="textPrimary">
                        Message: {getFirstSentence(notification.message)}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        Type: {notification.type}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        Order: {notification.order}
                      </Typography>
                      <br />
                      {notification.type === "tooltip" && (
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            Target Selector: {notification.targetSelector}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="textPrimary">
                            Position: {notification.position}
                          </Typography>
                          <br />
                        </>
                      )}
                      <Typography component="span" variant="body2" color="textPrimary">
                        Active: {notification.isActive ? "Yes" : "No"}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        Created At: {notification.createdAt ? new Date(notification.createdAt.seconds * 1000).toLocaleString() : "N/A"}
                      </Typography>
                    </>
                  }
                />
                <Button onClick={() => onSelectNotification(notification)}>Edit</Button>
                <Button onClick={() => handleDeleteNotification(notification.id)} color="error">
                  Delete
                </Button>
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
      </List>
    </div>
  );
}