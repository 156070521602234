import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "c96033ec2c9df06525b298b06027fb37T1JERVI6NDIxNDgsRVhQSVJZPTE2ODIwMjcyMzUwMDAsS0VZVkVSU0lPTj0x"
);
import { useStudioScrapeStatus } from "../contexts/StudioScrapeStatus/StudioScrapeStatusContext.js";
export default function BasicDateRangePicker(props) {
  const {
    selectedDates,
    dispatchDates,
    setGrabReportsButtonDisabled,
  } = useStudioScrapeStatus();
  const [value, setValue] = React.useState([null, null]);
  var startDate = "";
  var endDate = "";

  const handlePrevMonth = () => {
    var prevStartDate = new Date();
    prevStartDate.setDate(1);
    prevStartDate.setMonth(prevStartDate.getMonth() - 1);
    var prevEndDate = new Date();
    prevEndDate.setDate(0);

    handleOnChange([prevStartDate, prevEndDate]);
  };

  const handleOnChange = (newValue) => {
    setValue(newValue);

    dispatchDates({
      type: "UPDATE_DATES",
      dates: [startDate, endDate],
    });
    if (newValue[0] !== null && newValue[1] !== null) {
      setGrabReportsButtonDisabled(false);
    } else {
      setGrabReportsButtonDisabled(true);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        disabled={props.disabled}
        disableFuture
        className="date-range-picker"
        startText="Start of Pay Period"
        endText="End of Pay Period"
        value={value}
        onChange={handleOnChange}
        renderInput={(startProps, endProps) => {
          startDate = startProps.inputProps.value;
          endDate = endProps.inputProps.value;
          selectedDates.unshift(endDate);
          selectedDates.unshift(startDate);

          return (
            <Box className="date-range-picker__box">
              {/* <Button
                disabled={props.disabled}
                variant="outlined"
                size="small"
                style={{
                  paddingTop: "6px",
                  marginBottom: "12px",
                }}
                id="pastMonthDateRangeButton"
                className="date-range-picker__button"
                onClick={handlePrevMonth}
              >
                Past Month
              </Button> */}
              <Box
                className="date-range-picker__box"
                style={{
                  display: "flex",
                }}
              >
                <TextField
                  disabled={props.disabled}
                  className="date-range-picker__input-container"
                  id="dateRangePickerTextFieldStartDate"
                  {...startProps}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mx: 2,
                    opacity: props.disabled ? 0.2 : 1,
                  }}
                >
                  to
                </Box>
                <TextField
                  disabled={props.disabled}
                  className="date-range-picker__input-container"
                  id="dateRangePickerTextFieldEndDate"
                  {...endProps}
                />
              </Box>
            </Box>
          );
        }}
      />
    </LocalizationProvider>
  );
}
